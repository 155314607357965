import { sendResetLink } from "../../action/authAction";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button/standard-button";

const ForgotPasswordModal = ({}) => {
  const [values, setValues] = useState({
    email: "",
    message: "",
    loading: false,
    showForm: true,
  });

  const { email, message, loading, showForm } = values;

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });

    sendResetLink(email).then((data) => {
      setValues({
        ...values,
        loading: false,
        message: data.success || data.error,
      });
    });
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const showLaoding = () =>
    loading ? <div className="alert alert-info">Loading...</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";

  const resetForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Type your email"
              onChange={handleChange("email")}
              required
              type="email"
              value={email}
            />
          </div>
          <br />
          <Button style={{ float: "right" }} appearance="primary" type="submit">
            Submit
          </Button>
        </form>
      </>
    );
  };

  return (
    <div className="container">
      {showLaoding()}
      {showMessage()}
      {showForm && resetForm()}
    </div>
  );
};

export default withRouter(ForgotPasswordModal);
