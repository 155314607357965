import React, { useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Form, { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { API } from "../../../config/config";
import { ToastContainer, toast } from "react-toastify";
import { createDocumentTemplate } from "../../../action/documentTemplateAction";
import "react-toastify/dist/ReactToastify.css";

const NewTemplate = ({ isOpen, onClose, onSuccess }) => {
  const [formValues, setFormValues] = useState({
    documentTemplateName: "",
    description: "",
  });

  const [dynamicFields, setDynamicFields] = useState([]);

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDynamicFieldChange = (value, index) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index] = value;
    setDynamicFields(updatedFields);
  };

  const handleAddField = () => {
    setDynamicFields([...dynamicFields, ""]);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...dynamicFields];
    updatedFields.splice(index, 1);
    setDynamicFields(updatedFields);
  };

  const resetFormFields = () => {
    setFormValues({
      documentTemplateName: "",
      description: "",
    });
    setDynamicFields([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Add text fields to the form data
    formData.append("documentTemplateName", formValues.documentTemplateName);
    formData.append("description", formValues.description);

    // Add dynamic fields to the form data
    dynamicFields.forEach((field, index) => {
      formData.append(`dynamicField-${index}`, field);
    });

    // Add files to the form data
    const fileInput = document.getElementById("fileInput");
    if (fileInput.files.length > 0) {
      formData.append("file", fileInput.files[0]);
    }
    try {
      const response = await createDocumentTemplate(formData);

      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success(`Template added successfully!`);
        resetFormFields();
        onSuccess();
        onClose();
      }
    } catch (error) {
      toast.error(error.toString());
    }
  };

  return (
    <div>
      <ToastContainer />

      <ModalTransition>
        {isOpen && (
          <Modal onClose={onClose}>
            <ModalHeader>
              <ModalTitle>
                <b>New Custom Template</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                {({ formProps }) => (
                  <form {...formProps} id="projectForm">
                    <br />
                    <input type="file" id="fileInput" name="file" />
                    <br />
                    <br />

                    <Field name="documentTemplateName" label="Template Name">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          onChange={(e) =>
                            handleFormChange(
                              "documentTemplateName",
                              e.target.value
                            )
                          }
                          value={formValues.documentTemplateName}
                        />
                      )}
                    </Field>
                    <Field name="description" label="Description">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          onChange={(e) =>
                            handleFormChange("description", e.target.value)
                          }
                          value={formValues.description}
                        />
                      )}
                    </Field>
                    {dynamicFields.map((field, index) => (
                      <div key={index}>
                        <Field
                          name={`dynamicField-${index}`}
                          label={`Field ${index + 1}`}
                        >
                          {({ fieldProps }) => (
                            <Textfield
                              {...fieldProps}
                              onChange={(e) =>
                                handleDynamicFieldChange(e.target.value, index)
                              }
                              value={field}
                            />
                          )}
                        </Field>
                        <Button
                          style={{ marginTop: "10px" }}
                          onClick={() => handleDeleteField(index)}
                        >
                          Delete Field
                        </Button>
                      </div>
                    ))}

                    <br />
                  </form>
                )}
              </Form>
              <Button style={{ marginTop: "10px" }} onClick={handleAddField}>
                + Add Field
              </Button>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button
                appearance="primary"
                type="submit"
                form="projectForm"
                onClick={handleSubmit}
                autoFocus
              >
                Upload
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default NewTemplate;
