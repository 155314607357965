import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import ViewDocumentModal from "../../component/modals/projects/viewDocumentModal";
import Badge from "@atlaskit/badge";
import { API } from "../../config/config";
import cookie from "js-cookie";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));
const AllDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const { theme } = useTheme();
  const classes = useStyles();

  const [isViewDocumentModalOpen, setIsViewDocumentModalOpen] = useState(false); // Add state for the modal
  const [selectedDocument, setSelectedDocument] = useState({}); // Add state to store the selected document

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${API}/project-document/all/documents`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching documents");
      }

      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const openDocumentViewer = (document) => {
    setSelectedDocument(document); // Store the selected document
    setIsViewDocumentModalOpen(true); // Open the modal
  };

  const renderRows = () => {
    return documents.map((document) => ({
      key: document._id, // Assuming _id is the unique identifier of the project
      cells: [
        { key: "documentName", content: document.documentName },
        {
          key: "type",
          content: (
            <Badge appearance={document.generated ? "warning" : "primary"}>
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {document.generated ? "Generated" : "Uploaded"}
              </span>
            </Badge>
          ),
        },
        {
          key: "uploadedDate",
          content: new Date(document.createdAt).toLocaleDateString(), // Convert datetime to date
        }, // Add startDate field to project model
        {
          key: "actions",
          content: (
            <Button onClick={() => openDocumentViewer(document)}>View</Button>
          ),
        },
      ],
    }));
  };
  const tableHead = {
    cells: [
      { key: "documentName", content: "Document Name" },
      { key: "type", content: "Type" },
      { key: "uploadedDate", content: "Uploaded Date" },
      { key: "actions", content: "Actions" },
    ],
  };

  return (
    <div className="base-page">
      <ViewDocumentModal
        isOpen={isViewDocumentModalOpen}
        onClose={() => setIsViewDocumentModalOpen(false)}
        signedUrl={selectedDocument.signedUrl} // Pass the signedUrl of the selected document
        fileType={selectedDocument.fileType} // Pass the fileType of the selected document
      />
      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h5>All Documents</h5>
        <br />
        <DynamicTable
          head={tableHead}
          rows={renderRows()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
        />
      </div>
    </div>
  );
};

export default AllDocuments;
