import React, { useState } from "react";
import PreferencesIcon from "@atlaskit/icon/glyph/preferences";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import SendIcon from "@atlaskit/icon/glyph/send";
import IssuesIcon from "@atlaskit/icon/glyph/issues";
import { Link } from "react-router-dom";
import { ButtonItem, Section } from "@atlaskit/menu";
import { useTheme } from "../theme/ThemeContext";

const Settings = () => {
  const { theme } = useTheme();

  return (
    <div className="base-page">
      <h2 style={{ fontWeight: "600" }}>Settings</h2>
      <div>
        <Section title="Account">
          <Link
            className={`settings-link-${theme}`}
            to="/resetpassword"
            style={{ textDecoration: "none" }}
          >
            <ButtonItem
              iconBefore={<PreferencesIcon label="Change Password" />}
              description="Update your password to be more secure!"
            >
              Change Password
            </ButtonItem>
          </Link>
        </Section>
        {/* <Section title="Communication & Support">
          <ButtonItem
            iconBefore={<SendIcon label="Change Password" />}
            description="Invite people to share your projects!"
          >
            Invite People
          </ButtonItem>
        </Section> */}
      </div>
    </div>
  );
};

export default Settings;
