import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  validateEmailConfirmationToken,
  updateUserLocalStorage,
  isAuth,
  resendEmailConfirmation,
  fetchAndUpdateUserLocalStorage,
} from "../../action/authAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@atlaskit/button/standard-button";
import Flag from "@atlaskit/flag";
import { G300, R400 } from "@atlaskit/theme/colors";
import SuccessIcon from "@atlaskit/icon/glyph/check-circle";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));

const AccountConfirmation = () => {
  const history = useHistory(); // Initialize useHistory
  const { theme } = useTheme();
  const classes = useStyles();
  const { token } = useParams();
  const user = isAuth();

  const handleResendConfirmationLink = async () => {
    if (!user) {
      toast.error("Please sign in first.");
    } else {
      let response = await resendEmailConfirmation();
      if (response.ok) {
        toast.success("New email confirmation sent.");
      } else {
        toast.error("Error sending new email confirmation.");
      }
    }
  };

  useEffect(() => {
    const tokenCheck = async (token) => {
      console.log("tokenCheck: ", token);
      let response = await validateEmailConfirmationToken(token);
      if (response.ok) {
        toast.success("Email has been confirmed!");
        if (isAuth()) {
          updateUserLocalStorage({ emailConfirmed: true });
          history.replace("/dashboard");
        } else {
          history.replace("/signin");
        }
      } else {
        // Show error, and suggest requesting a new link
        if (!user) {
          toast.error(
            "Error confirming your email address. Sign in, and request a new email confirmation."
          );
        } else {
          toast.error(
            "Error confirming your email address. A new email has been sent, please confirm."
          );
          handleResendConfirmationLink();
        }
      }
    };

    console.log(token);
    if (token !== "" && token !== undefined) {
      tokenCheck(token);
    }
  }, []);

  return (
    <div className="base-page">
      <ToastContainer />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1>Hello {isAuth() ? isAuth().name.split(" ")[0] : ""}!</h1>
      </div>
      <br />
      <hr />
      <br />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <h4>Account Confirmation</h4>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "600px", minWidth: "450px", padding: "20px" }}>
          {isAuth() && isAuth().emailConfirmed === true ? (
            <Flag
              icon={<SuccessIcon primaryColor={G300} label="Success" />}
              id="1"
              key="1"
              title="1. Confirm email address"
            />
          ) : (
            <Flag
              icon={<ErrorIcon primaryColor={R400} label="ErrorIcon" />}
              id="1"
              key="1"
              title="1. Confirm email address"
              actions={[
                {
                  content: (
                    <div>
                      {" "}
                      <FontAwesomeIcon icon={faArrowsRotate} />
                      &nbsp;Refresh
                    </div>
                  ),
                  onClick: async () => {
                    await fetchAndUpdateUserLocalStorage();
                    history.push("/dashboard");
                  },
                },
                {
                  content: (
                    <div>
                      {" "}
                      <FontAwesomeIcon icon={faArrowsRotate} />
                      &nbsp; Resend Email Confirmation
                    </div>
                  ),
                  onClick: () => {
                    handleResendConfirmationLink();
                  },
                },
              ]}
            />
          )}

          <br />

          {isAuth() && isAuth().organisationId !== undefined && (
            <Flag
              icon={<SuccessIcon primaryColor={G300} label="Success" />}
              id="2"
              key="2"
              title="2. Create or join an organisation"
            />
          )}

          {isAuth() &&
            isAuth().organisationId === undefined &&
            isAuth().pendingOrganisationApproval === true && (
              <Flag
                icon={<ErrorIcon primaryColor={R400} label="ErrorIcon" />}
                id="2"
                key="2"
                title="2. Create or join an organisation"
                description="You are still awaiting for approval from your organisation owner."
                actions={[
                  {
                    content: (
                      <div>
                        {" "}
                        <FontAwesomeIcon icon={faArrowsRotate} />
                        &nbsp;&nbsp;Refresh
                      </div>
                    ),
                    onClick: async () => {
                      await fetchAndUpdateUserLocalStorage();
                      history.push("/dashboard");
                    },
                  },
                ]}
              />
            )}

          {isAuth() &&
            isAuth().organisationId === undefined &&
            isAuth().pendingOrganisationApproval === false && (
              <Flag
                icon={<ErrorIcon primaryColor={R400} label="ErrorIcon" />}
                id="2"
                key="2"
                title="2. Create or join an organisation"
                description="You are still awaiting for approval from your organisation owner."
                actions={[
                  {
                    content: <div>Create or join organisation</div>,
                    onClick: () => {
                      history.push("/create-organisation");
                    },
                  },
                ]}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default AccountConfirmation;
