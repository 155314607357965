import React, { useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { API } from "../../../config/config";
import cookie from "js-cookie";

const NewDocumentModal = ({
  isOpen,
  onClose,
  projectId,
  onSuccess,
  onFailure,
}) => {
  const [formValues, setFormValues] = useState({
    documentName: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("documentName", formValues.documentName);
    formData.append("file", selectedFile);
    formData.append("projectId", projectId);
    console.log("documentName: ", formValues.documentName);

    try {
      const response = await fetch(`${API}/project-document`, {
        method: "POST",
        credentials: "include",
        body: formData,
        headers: {
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (response.ok) {
        // Handle success
        onSuccess();
        onClose();
      } else {
        // Handle error
        onFailure();
      }
    } catch (error) {
      // Handle error
      onFailure();
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <b>Upload New Document</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {/* Add your document generation form or content here */}
            <p>Upload documents related to your project here:</p>
            <Field name="documentName" label="Document Name:">
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) =>
                    handleFormChange("documentName", e.target.value)
                  }
                  value={formValues.documentName}
                />
              )}
            </Field>

            <br />
            <input
              type="file"
              id="fileInput"
              name="file"
              onChange={handleFileChange}
            />
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={handleUpload}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default NewDocumentModal;
