import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import { getOrganisationOwnerEmail } from "../../../action/organisationAction"; // Update the path as necessary

const OrderManagementModal = ({ isOpen, onClose }) => {
  const [ownerEmail, setOwnerEmail] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchOrganisationOwnerEmail();
    }
  }, [isOpen]);

  const fetchOrganisationOwnerEmail = async () => {
    try {
      const response = await getOrganisationOwnerEmail();
      if (response.ok) {
        const { email } = await response.json();
        setOwnerEmail(email);
      } else {
        console.error("Failed to fetch organisation owner's email");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={onClose}>
            <ModalHeader>
              <ModalTitle>
                <b>Manage your orders</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                Click the button below to view invoices on Stripe. Log in to the
                following page with your organisation owner's email.
              </p>
              <p>
                <b>Your organisation owner's email is:</b>{" "}
                {ownerEmail || "Loading..."}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                href="https://billing.stripe.com/p/login/eVa02vfs273Rcq4aEE"
                target="_blank"
                appearance="primary"
                rel="noopener noreferrer" // This is important for security reasons when using target="_blank"
              >
                Manage Subscriptions
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default OrderManagementModal;
