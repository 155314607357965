import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import Badge from "@atlaskit/badge";
import { useHistory } from "react-router-dom";
import Button from "@atlaskit/button/standard-button";

const MoveToLargerDevice = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const returnUrl = queryParams.get("returnUrl");

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth >= 768) {
        history.push(returnUrl || "/");
      }
    };

    window.addEventListener("resize", checkWindowSize);

    // Initial check in case the user already opened the page on a larger screen
    checkWindowSize();

    return () => window.removeEventListener("resize", checkWindowSize);
  }, [history, returnUrl]);
  return (
    <div className="base-page">
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          placeItems: "center",
        }}
      >
        <div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <FontAwesomeIcon
              style={{ fontSize: "50px" }}
              icon={faMobileScreenButton}
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          ></div>
          <h1 style={{ justifyContent: "center", display: "flex" }}>
            Size matters...
          </h1>

          <p
            style={{
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            In this case size matters, please move back to a device with a
            larger screen e.g. Laptop/desktop.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MoveToLargerDevice;
