import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Field, Label } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { API } from "../../../config/config";
import cookie from "js-cookie";
import { fetchProjects } from "../../../action/chatAction";
import Select from "@atlaskit/select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SaveGeneratedDocumentModal = ({
  isOpen,
  onClose,
  file,
  onSuccess,
  onFailure,
}) => {
  const [formValues, setFormValues] = useState({
    documentName: "",
  });

  const [selectedProjectId, setSelectedProjectId] = useState("");

  const [projects, setProjects] = useState([]);

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpload = async () => {
    console.log("formValues.documentName: ", formValues.documentName);
    if (formValues.documentName === "") {
      toast.error("Enter a title for your document.");
      return;
    }
    console.log("selectedProjectId: ", selectedProjectId);
    if (selectedProjectId === "") {
      toast.error(
        "Select a project, if there are no projects create one first."
      );
      return;
    }
    const formData = new FormData();
    formData.append("documentName", formValues.documentName);
    formData.append("file", file);
    formData.append("projectId", selectedProjectId);

    try {
      const response = await fetch(`${API}/project-document`, {
        method: "POST",
        credentials: "include",
        body: formData,
        headers: {
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      console.log(response);

      if (response.ok) {
        // Handle success
        onSuccess();
        onClose();
      } else {
        // Handle error
        onFailure();
      }
    } catch (error) {
      console.log("errorerror: ", error);
      // Handle error
      onFailure();
    }
  };

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const response = await fetchProjects(); // Call the fetchProjects function
        if (response.ok) {
          const data = await response.json();
          let projectOptions;
          if (data.length === 0) {
            // If there are no projects, set "No Projects Created"
            projectOptions = [{ label: "No Projects Created", value: "" }];
          } else {
            // Map the projects to the desired format
            projectOptions = [
              ...data.map((project) => ({
                label: project.projectName,
                value: project._id,
              })),
            ];
          }
          setProjects(projectOptions); // Set the mapped projects in state
        } else {
          // Handle error here
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("An error occurred while fetching projects:", error);
      }
    };

    fetchProjectsData();
  }, []);

  return (
    <ModalTransition>
      <ToastContainer />

      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <b>Save document</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form id="saveGeneratedFileForm">
              {/* Add your document generation form or content here */}
              <p>Save generated file to pre-existing project</p>
              <Field name="documentName" label="Document Name:" isRequired>
                {({ fieldProps }) => (
                  <Textfield
                    {...fieldProps}
                    isRequired
                    onChange={(e) =>
                      handleFormChange("documentName", e.target.value)
                    }
                    value={formValues.documentName}
                  />
                )}
              </Field>

              <br />
              <Label htmlFor="select-project">Project:</Label>

              <Select
                inputId="select-project"
                classNamePrefix="react-select"
                options={projects}
                menuPosition="fixed"
                isMulti={false}
                isSearchable={true}
                isRequired
                placeholder={"Select a project"}
                onChange={(selectedOption) => {
                  setSelectedProjectId(selectedOption.value);
                }}
              />

              <br />
              <br />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={handleUpload}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default SaveGeneratedDocumentModal;
