import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./component/navigation/header";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import HomeAdmin from "./pages/admin/index";
import ManageOrganisations from "./pages/admin/manageOrganisations";
import HomeUser from "./pages/user/index";
import Sidebar from "./component/navigation/sidebar"; // Import the Sidebar component
import Protected from "./component/private/Protected";
import Homepage from "./pages/Homepage";
import AllProjects from "./pages/projects/all";
import AllDocuments from "./pages/documents/all";
import Legislation from "./pages/legislation/index";
import SharedProjects from "./pages/organisation/sharedProjects";
import Colleagues from "./pages/organisation/colleagues";
import AllOrders from "./pages/orders/all";
import Settings from "./pages/settings";
import ChatSidebar from "./component/navigation/chatSidebar";
import ChatPage from "./pages/chat/index";
import TemplatesPage from "./pages/templates/index";
import CaseLawPage from "./pages/caseLaw/index";
import ProjectView from "./pages/projects/projectView";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import CustomTemplatePage from "./pages/templates/custom";
import { useTheme } from "./theme/ThemeContext";
import { token, setGlobalTheme } from "@atlaskit/tokens";
import AccountConfirmationMiddleware from "./middleware/accountConfirmationMiddleware";
import AccountConfirmation from "./pages/user/accountConfirmation";
import UseHubSpotChat from "./component/hubspot/useHubSpotChat";
import CreateOrganisation from "./pages/organisation/createOrganisation";
import NotFound from "./pages/error/notFound";
import MoveToLargerDevice from "./pages/error/moveToLargerDevice";
function App() {
  const { theme } = useTheme();

  setGlobalTheme({
    light: "light",
    dark: "dark",
    colorMode: theme,
    typography: "typography",
  });

  // Use useEffect to update the body background color based on the theme
  useEffect(() => {
    // Define the body background color based on the theme
    const bodyBackgroundColor = theme === "dark" ? "#101118" : "white";
    const bodyColor = theme === "dark" ? "white" : "black";
    document.body.style.backgroundColor = bodyBackgroundColor;
    document.body.style.color = bodyColor;
  }, [theme]);
  return (
    <div
      style={{ backgroundColor: token("elevation.surface") }}
      className="App"
    >
      <Router>
        <Header />
        <UseHubSpotChat />
        <Protected />
        <Switch>
          <Route path="/" component={Homepage} exact />
          <Route path="/signup" component={SignUp} exact />
          <Route path="/signin" component={SignIn} exact />
          <Route path="/ForgotPassword" component={ForgotPassword} exact />
          <Route
            path="/resetpassword/:token?"
            render={(props) => <ResetPassword {...props} />}
            exact
          />
          <Route
            path="/confirm-account/:token?"
            component={AccountConfirmation}
            exact
          />
          <Route
            path="/moveToLargerDevice"
            component={MoveToLargerDevice}
            exact
          />

          <Route
            path="/create-organisation"
            component={CreateOrganisation}
            exact
          />

          <AccountConfirmationMiddleware>
            <Route path="/dashboard" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <HomeUser />
              </div>
            </Route>

            <Route path="/projects/all" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <AllProjects />
              </div>
            </Route>

            <Route path="/documents/all" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <AllDocuments />
              </div>
            </Route>
            <Route path="/organisation/shared-projects" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <SharedProjects />
              </div>
            </Route>
            <Route path="/organisation/colleagues" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Colleagues />
              </div>
            </Route>
            <Route path="/case-law" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <CaseLawPage />
              </div>
            </Route>
            <Route path="/legislation" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Legislation />
              </div>
            </Route>
            <Route path="/project/:projectId" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <ProjectView />
              </div>
            </Route>
            <Route path="/template/:templateId" exact>
              <div style={{ display: "flex", height: "100%" }}>
                <Sidebar />
                <CustomTemplatePage />
              </div>
            </Route>
            <Route path="/chat/:chatId?" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <ChatSidebar />
                <ChatPage />
              </div>
            </Route>
            <Route path="/orders/all" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <AllOrders />
              </div>
            </Route>
            <Route path="/templates" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <TemplatesPage />
              </div>
            </Route>
            <Route path="/settings" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Settings />
              </div>
            </Route>
            <Route path="/admin" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <HomeAdmin />
              </div>
            </Route>
            <Route path="/manage-organisations" exact>
              <div style={{ display: "flex" }}>
                <Sidebar />
                <ManageOrganisations />
              </div>
            </Route>
          </AccountConfirmationMiddleware>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
