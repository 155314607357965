import React, { useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { API } from "../../../config/config";
import cookie from "js-cookie";

const CreateOrganisationModal = ({ isOpen, onClose }) => {
  const [formValues, setFormValues] = useState({
    organisationName: "",
    owner: "",
    discountRatePercentage: "",
    organisationEmailDomain: "",
    maxSeats: "",
    currentTier: "",
  });

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API}/admin/create-organisation`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
        body: JSON.stringify(formValues),
      });

      if (response.ok) {
        // Organization creation successful, you can handle success here.
        onClose(); // Close the modal on success
      } else {
        // Handle errors if the request fails.
      }
    } catch (error) {
      // Handle any other errors, like network issues.
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <b>Create Organisation</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {/* Add your organisation creation form or content here */}
            <Field
              name="organisationName"
              label="Organisation Name (Required):"
            >
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) =>
                    handleFormChange("organisationName", e.target.value)
                  }
                  value={formValues.organisationName}
                />
              )}
            </Field>
            <Field
              name="organisationEmailDomain"
              label="Organisation Email Domain (Required):"
            >
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) =>
                    handleFormChange("organisationEmailDomain", e.target.value)
                  }
                  value={formValues.organisationEmailDomain}
                />
              )}
            </Field>
            <Field name="maxSeats" label="Max Seats (Required):">
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) => handleFormChange("maxSeats", e.target.value)}
                  value={formValues.maxSeats}
                />
              )}
            </Field>
            <Field name="currentTier" label="Current Tier (Required):">
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) =>
                    handleFormChange("currentTier", e.target.value)
                  }
                  value={formValues.currentTier}
                />
              )}
            </Field>
            {/* Add your organisation creation form or content here */}
            <Field name="owner" label="OwnerId:">
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) => handleFormChange("owner", e.target.value)}
                  value={formValues.owner}
                />
              )}
            </Field>
            <Field
              name="discountRatePercentage"
              label="Discount Rate Percentage:"
            >
              {({ fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  onChange={(e) =>
                    handleFormChange("discountRatePercentage", e.target.value)
                  }
                  value={formValues.discountRatePercentage}
                />
              )}
            </Field>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={handleSubmit}>
              Create
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default CreateOrganisationModal;
