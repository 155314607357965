import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import { getOrders } from "../../action/orderAction"; // Import the getOrders function
import { ToastContainer, toast } from "react-toastify";
import OrderManagementModal from "../../component/modals/orders/orderManagementModal";
import { requireRole } from "./../../action/authAction"; // Import the requireAdmin function

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const { theme } = useTheme();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchOrders(); // Fetch orders when the component mounts
  }, []);

  const formatCurrency = (cents) => {
    const dollars = (cents / 100).toFixed(2);
    return `$${dollars}`;
  };

  const fetchOrders = async () => {
    try {
      // Call the getOrders function to fetch orders
      const response = await getOrders();
      if (response.ok) {
        let data = await response.json();
        setOrders(data.data); // Set the fetched orders to the state
        console.log("data: ", data);
      }

      setIsLoading(false); // Set loading state to false
    } catch (error) {
      console.error("Error fetching orders:", error);
      setIsLoading(false); // Set loading state to false in case of an error
    }
  };

  const renderRows = () => {
    if (!Array.isArray(orders) || orders.length === 0) {
      return [
        {
          key: "no-orders",
          cells: [
            {
              key: "no-orders-label",
              content: <div>No orders</div>,
              colSpan: 4, // Set the colSpan to the number of columns in your table
            },
          ],
        },
      ];
    }

    return orders.map((order) => ({
      key: order.id, // Assuming 'id' is the unique identifier of the order
      cells: [
        { key: "orderId", content: order.id },
        { key: "amount", content: formatCurrency(order.amount) }, // Replace with the actual field names from your order data
        { key: "status", content: order.status }, // Replace with the actual field names from your order data
        {
          key: "actions",
          content: (
            <Button onClick={() => handleOrderClick(order)}>View Order</Button>
          ),
        },
      ],
    }));
  };

  const tableHead = {
    cells: [
      { key: "orderId", content: "Order ID" },
      { key: "amount", content: "Amount" },
      { key: "status", content: "Status" },
      { key: "actions", content: "Actions" },
    ],
  };

  const handleOrderClick = (order) => {
    setIsModalOpen(true); // Open the modal when an order is clicked
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="base-page">
      <ToastContainer />
      <OrderManagementModal isOpen={isModalOpen} onClose={closeModal} />

      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h5>
          All Orders{" "}
          <Button
            style={{ marginLeft: "20px", fontSize: "14px" }}
            onClick={() => handleOrderClick()}
          >
            Manage Subscriptions
          </Button>
        </h5>
        <br />
        {isLoading ? (
          <p>Loading orders...</p>
        ) : orders ? (
          <DynamicTable
            head={tableHead}
            rows={renderRows()}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={false}
          />
        ) : (
          <p>No orders available.</p>
        )}
      </div>

      {/* <div>
        <stripe-pricing-table
          pricing-table-id="prctbl_1O9VsJLZSCiN1D2Qaav4o11o"
          publishable-key="pk_live_51O8RyXLZSCiN1D2QUdqUMtfoeEMLlwAvSYshxAtNfTbw4xwjPMha80khcLielb9vxvvjcOGFWD9CUCYQqRlkt0xz00p4rE8KH5"
        ></stripe-pricing-table>
      </div> */}
    </div>
  );
};

export default requireRole([1, 99])(AllOrders);
