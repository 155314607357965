import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isAuth, signout } from "../../action/authAction";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useTheme } from "../../theme/ThemeContext";
import Button from "@atlaskit/button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudSun } from "@fortawesome/free-solid-svg-icons";

const Header = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { theme, toggleTheme } = useTheme();
  return (
    <div>
      <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
        <Navbar
          color=""
          expand="md"
          className={`header-navbar-${theme}`}
          style={{
            padding: "5px",
            position: "fixed",
            width: "100%",
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              display: "flex",
              paddingLeft: "10px",
            }}
            to="/"
          >
            <img
              src="../images/ContractAILogo.png"
              width={40}
              height={40}
              alt="Contractly AI Logo"
            />

            <NavbarBrand className={`header-navbar-brand-${theme}`}>
              Contractly
            </NavbarBrand>
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav
              navbar
              className="justify-content-end"
              style={{ width: "100%" }}
            >
              {!isAuth() && (
                <React.Fragment>
                  <NavItem>
                    <Link style={{ textDecoration: "none" }} to="/signup">
                      <NavLink className={`header-navlink-${theme}`}>
                        Sign Up
                      </NavLink>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      style={{ textDecoration: "none", paddingLeft: "20px" }}
                      to="/signin"
                    >
                      <button
                        className="btn btn-primary"
                        style={{ float: "right" }}
                      >
                        Sign In
                      </button>
                    </Link>
                  </NavItem>
                </React.Fragment>
              )}

              {isAuth() && (isAuth().role === 0 || isAuth().role === 1) && (
                <>
                  <NavItem>
                    <Link style={{ textDecoration: "none" }} to="/dashboard">
                      <NavLink className={`header-navlink-${theme}`}>{`${
                        isAuth().name
                      }'s Dashboard`}</NavLink>
                    </Link>
                  </NavItem>
                  <Button appearance="subtle" onClick={toggleTheme}>
                    {" "}
                    <FontAwesomeIcon icon={faCloudSun} />
                  </Button>
                </>
              )}

              {isAuth() && isAuth().role === 99 && (
                <>
                  <NavItem>
                    <Link style={{ textDecoration: "none" }} to="/dashboard">
                      <NavLink className={`header-navlink-${theme}`}>{`${
                        isAuth().name
                      }'s Dashboard`}</NavLink>
                    </Link>
                  </NavItem>
                  <Button appearance="subtle" onClick={toggleTheme}>
                    {" "}
                    <FontAwesomeIcon icon={faCloudSun} />
                  </Button>
                </>
              )}

              {isAuth() && (
                <NavItem>
                  <NavLink
                    className={`header-navlink-${theme}`}
                    onClick={() => signout(() => history.push("/signin"))}
                  >
                    Sign Out
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      {/* Spacer */}
      <div style={{ height: "50px" }}></div>
    </div>
  );
};

export default withRouter(Header);
