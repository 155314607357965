import { signin, authenticate, isAuth } from "../../action/authAction";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTheme } from "../../theme/ThemeContext";
import { API } from "../../config/config";
import Textfield from "@atlaskit/textfield";
import cookie from "js-cookie";

const SignInModal = ({ history, returnUrl }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    message: "",
    showForm: true,
  });

  const { theme, toggleTheme } = useTheme();

  const { email, password, error, loading, message, showForm } = values;

  useEffect(() => {
    isAuth() && history.push("/");
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.table({ name, email, password, error, loading, message, showForm });

    setValues({ ...values, loading: true, error: false });
    const user = { email, password };

    try {
      const data = await signin(user);
      if (!data) {
        setValues({ ...values, error: "Sign in error", loading: false });
      } else if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        authenticate(data, () => {
          setTheme();
          if (isAuth() && isAuth().role === 99) {
            history.push("/admin");
          } else {
            if (returnUrl && returnUrl !== "/" && returnUrl !== "/signin") {
              history.push(returnUrl);
            } else {
              history.push("/dashboard");
            }
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setTheme = async () => {
    try {
      // Fetch the user's current theme from the server
      const userTheme = await getTheme(); // Assuming getTheme is a function that fetches the user's theme from the server

      if (userTheme && userTheme !== theme) {
        toggleTheme(); // Update the theme in the React context
      }
    } catch (error) {
      console.error("Error setting theme:", error);
    }
  };

  const getTheme = async () => {
    try {
      const response = await fetch(`${API}/user/theme/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user theme");
      }
      if (response.ok) {
        const data = await response.json();
        const userTheme = data.theme;
        return userTheme;
      }
    } catch (error) {
      console.error("Error fetching user theme:", error);
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const showLaoding = () =>
    loading ? <div className="alert alert-info">Loading...</div> : "";
  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";

  const signinForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Type your email"
              onChange={handleChange("email")}
              required
              type="email"
              value={email}
            />
            <br />

            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Type your password"
              onChange={handleChange("password")}
              required
              type="password"
              value={password}
            />
          </div>
          <br />
          <button className="btn btn-primary" style={{ float: "right" }}>
            Sign In
          </button>
        </form>
        <p>
          <Link to="/ForgotPassword">Forgot Password?</Link>
        </p>
      </>
    );
  };

  return (
    <div className="container">
      {showLaoding()}
      {showError()}
      {showMessage()}
      {showForm && signinForm()}
    </div>
  );
};

export default withRouter(SignInModal);
