import React, { useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import Form, { Field } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import { deleteChatMessageChain } from "../../../action/chatAction";

const DeleteChatModal = ({ isOpen, onClose, chat, onSuccess, onFailure }) => {
  const [formData, setFormData] = useState({
    title: chat ? chat.title : "",
    // Add other fields from the chat data here
  });

  const handleSubmit = async () => {
    // Call the updateChatMessageChain function with formData
    try {
      await deleteChatMessageChain(chat._id); // Assuming you have an _id field in your chat object
      onSuccess();
      onClose(); // Close the modal on successful submission
    } catch (error) {
      // Handle any errors here
      onFailure();
      console.error("Error updating chat:", error);
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle>
              <b>Delete - {chat ? chat.title : ""}</b>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this chat chain?
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} type="submit" appearance="primary">
              delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default DeleteChatModal;
