import React, { useEffect, useState } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import Form, { Field, Label } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import { API } from "../../../config/config";
import { getAllColleagues } from "../../../action/colleagueAction";
import cookie from "js-cookie";
import { isAuth } from "../../../action/authAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";

const NewProject = ({ isOpen, onClose }) => {
  const [formValues, setFormValues] = useState({
    projectName: "",
    projectDescription: "",
    colleagues: [],
  });
  const history = useHistory();

  const [colleagues, setColleagues] = useState([]); // To store fetched colleagues

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };
  useEffect(() => {
    // Fetch colleagues when the modal is opened
    if (isOpen) {
      getAllColleagues()
        .then((colleagues) => {
          // Map colleagues to the format you want (label as name and value as _id)
          const mappedColleagues = colleagues.map((colleague) => ({
            label: colleague.name,
            value: colleague._id,
          }));
          setColleagues(mappedColleagues);
        })
        .catch((error) => console.error("Error fetching colleagues:", error));
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare data with selected colleagues
      const requestData = {
        ...formValues,
        colleagues: formValues.colleagues.map((colleague) => colleague.value),
      };

      const response = await fetch(`${API}/projects`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success("New project created successfully");
        history.push(`/project/${data._id}`);
        onClose();
      } else {
        throw new Error("Error creating project");
      }
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };
  return (
    <div>
      <ToastContainer />

      <ModalTransition>
        {isOpen && (
          <Modal onClose={onClose}>
            <ModalHeader>
              <ModalTitle>
                <b>New Project</b>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Form>
                {({ formProps }) => (
                  <form {...formProps} onSubmit={handleSubmit} id="projectForm">
                    <Field name="projectName" label="Project Name" isRequired>
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          onChange={(e) =>
                            handleFormChange("projectName", e.target.value)
                          }
                          value={formValues.projectName}
                        />
                      )}
                    </Field>
                    <Field name="projectDescription" label="Description">
                      {({ fieldProps }) => (
                        <Textfield
                          {...fieldProps}
                          onChange={(e) =>
                            handleFormChange(
                              "projectDescription",
                              e.target.value
                            )
                          }
                          value={formValues.projectDescription}
                        />
                      )}
                    </Field>
                    <br />
                    <Label htmlFor="multi-select-example">Add Colleagues</Label>

                    <Select
                      inputId="multi-select-example"
                      className="multi-select"
                      classNamePrefix="react-select"
                      options={colleagues} // Use the fetched colleagues
                      menuPosition="fixed"
                      isMulti
                      isSearchable={true}
                      placeholder="Add Colleagues"
                      // Store selected colleagues in formValues.colleagues
                      onChange={(selectedOptions) =>
                        setFormValues({
                          ...formValues,
                          colleagues: selectedOptions,
                        })
                      }
                    />
                  </form>
                )}
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button
                appearance="primary"
                type="submit"
                form="projectForm"
                autoFocus
              >
                Create
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default NewProject;
