import React from "react";
import Drawer from "@atlaskit/drawer";
const SearchProjectModal = ({ isOpen, onClose }) => {
  return (
    <Drawer width={"extended"} onClose={onClose} isOpen={isOpen}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "200px", height: "100%" }}>side nav</div>
        <div style={{ paddingLeft: "50px" }}>
          {" "}
          <h2>Content</h2>
        </div>
      </div>
    </Drawer>
  );
};

export default SearchProjectModal;
