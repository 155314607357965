import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import { SkeletonHeadingItem } from "@atlaskit/menu"; // Import Skeleton component
import AddonIcon from "@atlaskit/icon/glyph/addon";
import AddItemIcon from "@atlaskit/icon/glyph/add-item";
import Button, { ButtonGroup } from "@atlaskit/button";
import SearchIcon from "@atlaskit/icon/glyph/search";
import NewDocumentModal from "../../component/modals/projects/newDocument";
import GenerateDocumentModal from "../../component/modals/projects/generateDocument";
import SearchProjectModal from "../../component/modals/projects/searchProject";
import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import DynamicTable from "@atlaskit/dynamic-table";
import ViewDocumentModal from "../../component/modals/projects/viewDocumentModal";
import { useHistory } from "react-router-dom";
import { API } from "../../config/config";
import { fetchProjectPermissionsAdditionalData } from "../../action/projectPermissionAction";
import { closeProject, openProject } from "../../action/projectAction";
import { ToastContainer, toast } from "react-toastify";
import cookie from "js-cookie";

const ProjectView = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };
  const history = useHistory();
  const [isViewDocumentModalOpen, setIsViewDocumentModalOpen] = useState(false); // Add state for the modal
  const [selectedDocument, setSelectedDocument] = useState({}); // Add state to store the selected document

  const openDocumentViewer = (document) => {
    setSelectedDocument(document); // Store the selected document
    setIsViewDocumentModalOpen(true); // Open the modal
  };

  const [documents, setDocuments] = useState([]);
  const [projectPermissions, setProjectPermissions] = useState([]);

  // States for modals
  const [isNewDocumentModalOpen, setIsNewDocumentModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isGenerateDocumentModalOpen, setIsGenerateDocumentModalOpen] =
    useState(false);

  useEffect(() => {
    fetchProject(projectId);
    fetchProjectPermissionsData(projectId);
    fetchDocuments();
  }, [projectId]);

  const handlePreviousSearches = () => {
    history.push("/chat/new");
  };

  const uploadSuccess = () => {
    fetchDocuments();
    toast.success("Document added successfully");
  };

  const uploadFailure = () => {
    toast.error("An error occured whilst uploading your document.");
  };

  const fetchProjectPermissionsData = async (projectId) => {
    try {
      const response = await fetchProjectPermissionsAdditionalData(projectId);
      if (response.ok) {
        const data = await response.json();
        setProjectPermissions(data);
      }
    } catch (error) {
      console.log("Error fetching project permission: ", error);
    }
  };

  const handleOpenProject = async () => {
    try {
      const response = await openProject(projectId);
      if (response.ok) {
        // Show success toast message
        toast.success("Project opened successfully");
        await fetchProject(projectId);

        // You can also update the project status or handle any other logic here
      } else {
        // Show error toast message
        toast.error("Failed to open project");
      }
    } catch (error) {
      console.error("Error opening project:", error);
      // Show error toast message
      toast.error("An error occurred while opening the project");
    }
  };

  const handleCloseProject = async () => {
    try {
      const response = await closeProject(projectId);
      if (response.ok) {
        // Show success toast message
        toast.success("Project closed successfully");
        await fetchProject(projectId);

        // You can also update the project status or handle any other logic here
      } else {
        // Show error toast message
        toast.error("Failed to close project");
      }
    } catch (error) {
      console.error("Error closing project:", error);
      // Show error toast message
      toast.error("An error occurred while closing the project");
    }
  };

  const fetchProject = async (projectId) => {
    try {
      const response = await fetch(`${API}/project/${projectId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          setError("Unauthorized to view this project");
        } else {
          setError("Error fetching project");
        }
        setLoading(false); // Stop loading
        return;
      }

      const data = await response.json();
      setProject(data);
      setLoading(false); // Stop loading
    } catch (error) {
      setError("Error fetching project");
      setLoading(false); // Stop loading
      console.error("Error fetching project:", error);
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${API}/project-documents/${projectId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching projects");
      }

      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const tableHead = {
    cells: [
      { key: "documentName", content: "Document Name" },
      { key: "uploadedDate", content: "Uploaded Date" },
      { key: "actions", content: "Actions" },
    ],
  };

  const renderRows = () => {
    return documents.map((document) => ({
      key: document._id, // Assuming _id is the unique identifier of the project
      cells: [
        { key: "documentName", content: document.documentName },
        {
          key: "uploadedDate",
          content: new Date(document.createdAt).toLocaleDateString(), // Convert datetime to date
        }, // Add startDate field to project model
        {
          key: "actions",
          content: (
            <Button onClick={() => openDocumentViewer(document)}>View</Button>
          ),
        },
      ],
    }));
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ padding: "20px", width: "100%", display: "block" }}>
      <Breadcrumbs>
        <BreadcrumbsItem href="/projects/all" text="Projects /" />
      </Breadcrumbs>
      <ToastContainer />

      <ViewDocumentModal
        isOpen={isViewDocumentModalOpen}
        onClose={() => setIsViewDocumentModalOpen(false)}
        signedUrl={selectedDocument.signedUrl} // Pass the signedUrl of the selected document
        fileType={selectedDocument.fileType} // Pass the fileType of the selected document
      />
      <br />
      {loading ? (
        // Render Skeleton while loading
        <>
          <SkeletonHeadingItem width="200px" />
          <br />
          <SkeletonHeadingItem width="500px" isShimmering />
        </>
      ) : (
        <>
          <div
            style={{
              paddingBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h2
              style={{
                fontWeight: 700,
                fontSize: "35px",
                fontFamily: "Poppins",
              }}
            >
              {project.projectName}
            </h2>
          </div>
          <div
            style={{
              paddingBottom: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {project.projectDescription}
          </div>
          <div
            style={{
              paddingBottom: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonGroup>
              <Button
                iconBefore={<SearchIcon label="" size="medium" />}
                appearance="primary"
                onClick={handlePreviousSearches}
              >
                Chat with documents
              </Button>
              <Button
                iconBefore={<AddItemIcon label="" size="medium" />}
                appearance="primary"
                onClick={() => setIsNewDocumentModalOpen(true)}
              >
                Add Document
              </Button>

              <Button
                iconBefore={<AddonIcon label="" size="medium" />}
                appearance="primary"
                onClick={() => history.push("/templates")}
              >
                Generate Document
              </Button>
            </ButtonGroup>
          </div>
          {/* Modals */}
          <NewDocumentModal
            isOpen={isNewDocumentModalOpen}
            onClose={() => setIsNewDocumentModalOpen(false)}
            projectId={projectId}
            onSuccess={() => uploadSuccess()}
            onFailure={() => uploadFailure()}
          />
          <SearchProjectModal
            isOpen={isSearchModalOpen}
            onClose={() => setIsSearchModalOpen(false)}
          />
          <GenerateDocumentModal
            isOpen={isGenerateDocumentModalOpen}
            onClose={() => setIsGenerateDocumentModalOpen(false)}
          />
          <div style={{ width: "100%", paddingTop: "50px" }}>
            <Tabs
              onChange={handleSelectTab}
              selected={selectedTab}
              id="controlled"
            >
              <TabList>
                <Tab>All Files</Tab>
                <Tab>Info</Tab>
                <Tab>Settings</Tab>
              </TabList>
              <TabPanel>
                <div
                  style={{ padding: "20px", width: "100%", display: "block" }}
                >
                  <DynamicTable
                    head={tableHead}
                    rows={renderRows()}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={false}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{ padding: "20px", width: "100%", display: "block" }}
                >
                  <h5>Project Description</h5>
                  <p>{project.projectDescription}</p>
                  <br />
                  <br />
                  <h5>Colleagues with Access</h5>
                  <ul>
                    {projectPermissions.map((permission) => (
                      <li key={permission._id}>
                        {/* Display user information associated with project permission */}
                        {permission.userId && (
                          <>
                            <strong>{permission.userId.name}</strong> (
                            {permission.accessLevel})
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{ padding: "20px", width: "100%", display: "block" }}
                >
                  <h5> Project Settings</h5>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {project && project.projectStatus === "Closed" ? (
                      // Show "Open" button if the project is closed
                      <Button appearance="primary" onClick={handleOpenProject}>
                        Reopen project
                      </Button>
                    ) : (
                      <>
                        <Button
                          appearance="danger"
                          onClick={handleCloseProject}
                        >
                          Close project
                        </Button>
                        <p style={{ paddingLeft: "20px", paddingTop: "5px" }}>
                          You can reopen the project later
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectView;
