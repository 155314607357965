import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const fetchProjectPermissions = async (projectId) => {
  try {
    const response = await fetch(`${API}/project-permissions/${projectId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const fetchProjectPermissionsAdditionalData = async (projectId) => {
  try {
    const response = await fetch(
      `${API}/project-permissions/additional-data/${projectId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
