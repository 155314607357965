import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button/standard-button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import DocumentViewer from "../../../component/misc/DocumentViewer";

const ViewDocumentModal = ({ isOpen, onClose, signedUrl, fileType }) => {
  const [openedFile, setOpenedFile] = useState({
    fileLocation: null,
    fileType: null,
  });

  // When the modal is opened, set the openedFile state
  useEffect(() => {
    if (isOpen && signedUrl && fileType) {
      openDocumentViewer(signedUrl, fileType);
    }
  }, [isOpen, signedUrl, fileType]);

  // Reset the openedFile state when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setOpenedFile({
        fileLocation: null,
        fileType: null,
      });
    }
  }, [isOpen]);

  const openDocumentViewer = (fileLocation, fileType) => {
    setOpenedFile({
      fileLocation,
      fileType,
    });
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal width="90%" onClose={onClose}>
          <ModalHeader>
            <ModalTitle>View Document</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {openedFile.fileLocation && openedFile.fileType ? (
              <DocumentViewer
                fileLocation={openedFile.fileLocation}
                fileType={openedFile.fileType}
              />
            ) : (
              <div>Error: Missing file information</div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button appearance="primary" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ViewDocumentModal;
