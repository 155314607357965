import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useTheme } from "../../theme/ThemeContext";
import MoreIcon from "@atlaskit/icon/glyph/more";
import Button from "@atlaskit/button/standard-button";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { fetchRecentChats } from "../../action/chatAction";
import EditChatModal from "../modals/chat/editChatModal";
import DeleteChatModal from "../modals/chat/deleteChatModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChatSidebar = () => {
  const [collapsed] = useState(false);
  const { theme } = useTheme();
  const [recentChats, setRecentChats] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);

  const openEditModal = (chat) => {
    setSelectedChat(chat);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedChat(null);
  };

  const editChatSuccess = () => {
    FetchRecentChats();
    toast.success("Chat title updated successfully!");
  };

  const editChatFailure = () => {
    FetchRecentChats();
    toast.error("There was an error updating the title of your chat.");
  };

  const openDeleteModal = (chat) => {
    setSelectedChat(chat);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedChat(null);
  };

  const deleteChatSuccess = () => {
    FetchRecentChats();
    toast.success("Chat deleted successfully!");
  };

  const deleteChatFailure = () => {
    FetchRecentChats();
    toast.error("There was an error deleting your chat.");
  };

  useEffect(() => {
    FetchRecentChats();
  }, []);

  const FetchRecentChats = async () => {
    const response = await fetchRecentChats();
    const data = await response.json();
    setRecentChats(data);
  };

  return (
    <div
      style={{
        marginLeft: "25px",
      }}
      className={`custom-navbar-outer ${collapsed ? "collapsed" : ""}`}
    >
      <nav
        style={{
          borderLeft: "3px solid rgb(255, 255, 255, 0.4)",
          height: "100%",
          marginTop: "0px",
          top: "50px",
        }}
        className={`custom-navbar-${theme} scrollbar-hidden ${
          collapsed ? "collapsed" : ""
        }`}
      >
        <div className={`side-nav-title-${theme}`}>CHATS</div>
        <EditChatModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          chat={selectedChat}
          onSuccess={editChatSuccess}
          onFailure={editChatFailure}
        />

        <DeleteChatModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          chat={selectedChat}
          onSuccess={deleteChatSuccess}
          onFailure={deleteChatFailure}
        />

        <ul className="navbar-items">
          <li>
            <NavLink
              to="/chat/new" // Replace with the appropriate URL for creating a new chat
              className="side-nav-button-add-chat"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faPlus} />
              {!collapsed && <span className="side-nav-text">New Chat</span>}
            </NavLink>
          </li>
          {recentChats.map((chat, index) => (
            <li key={index}>
              <NavLink
                to={`/chat/${chat._id}`} // Assuming each chat has a unique ID
                className="side-nav-button"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={faComment} />
                {!collapsed && (
                  <span>
                    <span
                      className="side-nav-text title"
                      style={{ paddingTop: "10px" }}
                    >
                      {chat.title}
                    </span>
                    <span style={{ float: "right", color: "black" }}>
                      <DropdownMenu
                        trigger={({ triggerRef, ...props }) => (
                          <Button
                            {...props}
                            iconBefore={<MoreIcon label="more" />}
                            ref={triggerRef}
                          />
                        )}
                      >
                        <DropdownItemGroup>
                          <DropdownItem onClick={() => openEditModal(chat)}>
                            Edit
                          </DropdownItem>
                          <DropdownItem onClick={() => openDeleteModal(chat)}>
                            Delete
                          </DropdownItem>
                        </DropdownItemGroup>
                      </DropdownMenu>
                    </span>
                  </span>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default ChatSidebar;
