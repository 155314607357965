import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const fetchProjectDocumentById = async (id) => {
  try {
    const response = await fetch(`${API}/project-document/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
