import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const getAllColleagues = async () => {
  try {
    const response = await fetch(`${API}/colleagues`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch colleagues. Please try again later.");
    }

    const data = await response.json();
    return data; // Assuming data is an array of colleagues
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};

export const updateColleagueSeat = async (data) => {
  try {
    const response = await fetch(`${API}/colleague/seat-update`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error ||
          "Failed to update seat status. Please contact support."
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is an array of colleagues
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};
