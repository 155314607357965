import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCog,
  faPlus,
  faBars,
  faFolderOpen,
  faCreditCard,
  faShare,
  faPeopleGroup,
  faCube,
  faFile,
  faGavel,
  faScaleBalanced,
  faPeopleRoof,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import NewProject from "../modals/projects/newProject";
import { useTheme } from "../../theme/ThemeContext";
import { isAuth } from "../../action/authAction";

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const { theme } = useTheme();

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const openNewProjectModal = () => {
    setShowNewProjectModal(true);
  };

  const closeNewProjectModal = () => {
    setShowNewProjectModal(false);
  };

  return (
    <div className={`custom-navbar-outer ${collapsed ? "collapsed" : ""}`}>
      <nav className={`custom-navbar-${theme} ${collapsed ? "collapsed" : ""}`}>
        <NewProject
          isOpen={showNewProjectModal}
          onClose={closeNewProjectModal}
        />

        <br />

        {isAuth() && isAuth().role === 99 && (
          <>
            {!collapsed && (
              <div className={`side-nav-title-${theme}`}>Admin</div>
            )}
            <ul className="navbar-items">
              <li>
                <NavLink
                  to="/admin"
                  className="side-nav-button"
                  activeClassName="active"
                >
                  <FontAwesomeIcon icon={faPeopleRoof} />
                  {!collapsed && (
                    <span className="side-nav-text title">Admin Panel</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/manage-organisations"
                  className="side-nav-button"
                  activeClassName="active"
                >
                  <FontAwesomeIcon icon={faPeopleRoof} />
                  {!collapsed && (
                    <span className="side-nav-text title">
                      Manage Organisations
                    </span>
                  )}
                </NavLink>
              </li>
            </ul>
          </>
        )}
        <div className={`side-nav-title-${theme}`}>MENU</div>
        <ul className="navbar-items">
          <li>
            <NavLink
              to="/dashboard"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faHome} />
              {!collapsed && (
                <span className="side-nav-text title">Dashboard</span>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/templates"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faCube} />
              {!collapsed && (
                <span className="side-nav-text title">
                  Generative Templates
                </span>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/chat/new"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faComment} />
              {!collapsed && (
                <span className="side-nav-text title">Document Chat</span>
              )}
            </NavLink>
          </li>
          <li>
            <div
              className="side-nav-button"
              onClick={openNewProjectModal} // Add this line
            >
              <FontAwesomeIcon icon={faPlus} />
              {!collapsed && (
                <span className="side-nav-text title">New Project</span>
              )}
            </div>
          </li>
          <li>
            <NavLink
              to="/projects/all"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faFolderOpen} />
              {!collapsed && (
                <span className="side-nav-text title">Projects</span>
              )}
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/documents/all"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faFile} />
              {!collapsed && (
                <span className="side-nav-text title">Documents</span>
              )}
            </NavLink>
          </li>
        </ul>
        {!collapsed && (
          <div className={`side-nav-title-${theme}`}>ORGANISATION</div>
        )}
        <ul className="navbar-items">
          <li>
            <NavLink
              to="/organisation/colleagues"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faPeopleGroup} />
              {!collapsed && (
                <span className="side-nav-text title">Colleagues</span>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/organisation/shared-projects"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faShare} />
              {!collapsed && (
                <span className="side-nav-text title">Shared Projects</span>
              )}
            </NavLink>
          </li>
        </ul>
        {!collapsed && <div className={`side-nav-title-${theme}`}>OTHER</div>}
        <ul className="navbar-items">
          <li>
            <NavLink
              to="/legislation"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faGavel} />
              {!collapsed && (
                <span className="side-nav-text title">Legislation</span>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/case-law"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faScaleBalanced} />
              {!collapsed && (
                <span className="side-nav-text title">Case Law</span>
              )}
            </NavLink>
          </li>
          {isAuth() && (isAuth().role === 1 || isAuth().role === 99) && (
            <li>
              <NavLink
                to="/orders/all"
                className="side-nav-button"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={faCreditCard} />
                {!collapsed && (
                  <span className="side-nav-text title">Orders</span>
                )}
              </NavLink>
            </li>
          )}

          <li>
            <NavLink
              to="/settings"
              className="side-nav-button"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={faCog} />
              {!collapsed && (
                <span className="side-nav-text title">Settings</span>
              )}
            </NavLink>
          </li>
        </ul>

        <br />

        <div className="navbar-toggle" onClick={toggleNavbar}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
