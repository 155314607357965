import React, { createContext, useContext, useState } from "react";
import { API } from "../config/config";
import cookie from "js-cookie";

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState("dark"); // You can set the initial theme here

  const toggleTheme = () => {
    let newTheme = theme === "light" ? "dark" : "light";
    ChangeDatabaseTheme(newTheme);
    setTheme(newTheme);
  };

  const ChangeDatabaseTheme = async (newTheme) => {
    return await fetch(`${API}/user/set-theme/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ theme: newTheme }),
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
