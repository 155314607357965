import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom"; // Import useHistory
import Badge from "@atlaskit/badge";
import { API } from "../../config/config";
import cookie from "js-cookie";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));

const All = () => {
  const [projects, setProjects] = useState([]);
  const history = useHistory();
  const { theme } = useTheme();
  const classes = useStyles();

  useEffect(() => {
    fetchProjects();
  }, []);

  const backgroundStyle = {
    backgroundColor: theme === "dark" ? "black" : "white",
    color: theme === "dark" ? "white" : "black",
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${API}/projects`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching projects");
      }

      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleViewProject = (projectId) => {
    history.push(`/project/${projectId}`);
  };

  const getOpenProjects = () => {
    var openProjects = [];
    projects.map((project) => {
      var projectStatus = project.projectStatus;
      if (projectStatus === "Open" || "Paused") {
        openProjects.push(project);
      }
    });
    return openProjects;
  };

  const getClosedProjects = () => {
    var closedProjects = [];
    projects.map((project) => {
      var projectStatus = project.projectStatus;
      if (projectStatus === "Closed") {
        closedProjects.push(project);
      }
    });
    return closedProjects;
  };

  const renderRows = (projectsList) => {
    return projectsList.map((project) => ({
      key: project._id,
      cells: [
        { key: project.projectName, content: project.projectName }, // Make project name key unique and sortable
        {
          key: "status",
          content: (
            <Badge
              appearance={
                project.projectStatus === "Open" ? "primary" : "warning"
              }
            >
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {project.projectStatus}
              </span>
            </Badge>
          ),
        }, // Add status field to project model
        {
          key: new Date(project.createdAt).toLocaleDateString(), // Make date field key unique and sortable
          content: new Date(project.createdAt).toLocaleDateString(), // Convert datetime to date
        },
        {
          key: "actions",
          content: (
            <Button onClick={() => handleViewProject(project._id)}>View</Button>
          ),
        },
      ],
    }));
  };

  const tableHead = {
    cells: [
      { key: "projectName", content: "Project Name", isSortable: true },
      { key: "status", content: "Status" },
      { key: "startDate", content: "Start Date", isSortable: true },
      { key: "actions", content: "Actions" },
    ],
  };

  return (
    <div className="base-page">
      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h1> All Projects</h1>
        <br />
        <h3>Open Projects</h3>
        <DynamicTable
          head={tableHead}
          rows={renderRows(getOpenProjects())}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
          sortKey="startDate"
          sortOrder="ASC"
        />
        <br />
        <h3>Closed Projects</h3>
        <DynamicTable
          head={tableHead}
          rows={renderRows(getClosedProjects())}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
          sortKey="startDate"
          sortOrder="ASC"
        />
      </div>
    </div>
  );
};

export default All;
