import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import Badge from "@atlaskit/badge";

const CaseLaw = () => {
  return (
    <div className="base-page">
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          placeItems: "center",
        }}
      >
        <div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <FontAwesomeIcon
              style={{ fontSize: "50px" }}
              icon={faScaleBalanced}
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <Badge appearance="added">Coming soon!</Badge>
          </div>
          <h1 style={{ justifyContent: "center", display: "flex" }}>
            Case Law
          </h1>

          <p style={{ justifyContent: "center", display: "flex" }}>
            Find relevant Case Law in an instant!
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseLaw;
