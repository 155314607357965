import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash } from "@fortawesome/free-solid-svg-icons";
import Badge from "@atlaskit/badge";
import { useHistory } from "react-router-dom";
import Button from "@atlaskit/button/standard-button";

const NotFound = () => {
  const history = useHistory();

  return (
    <div className="base-page">
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          placeItems: "center",
        }}
      >
        <div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <FontAwesomeIcon style={{ fontSize: "50px" }} icon={faLinkSlash} />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <Badge appearance="removed">404 Error</Badge>
          </div>
          <h1 style={{ justifyContent: "center", display: "flex" }}>
            Page not found
          </h1>

          <p style={{ justifyContent: "center", display: "flex" }}>
            We couldn't find the page you were after. Please reach out to
            support if there is an issue.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                style={{ marginRight: "20px" }}
                appearance="subtle"
                onClick={() => history.push("/signin")}
              >
                Sign In
              </Button>
              <Button
                appearance="primary"
                onClick={() => history.push("/dashboard")}
              >
                Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
