import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RichTextEditor from "react-rte";
import TextField from "@atlaskit/textfield";
import Form, { Label } from "@atlaskit/form";
import Button from "@atlaskit/button/standard-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWandMagicSparkles,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import BookLoadingAnimation from "../../component/misc/BookLoadingAnimation";
import { useParams } from "react-router-dom";
import {
  getDocumentTemplateInputs,
  getDocumentTemplateById,
} from "../../action/documentTemplateAction";
import {
  generateDocument,
  generateDocumentStream,
} from "../../action/generateDocumentAction";
import { ChaoticOrbit } from "@uiball/loaders";
import SaveGeneratedDocumentModal from "../../component/modals/chat/saveGeneratedDocumentModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  basePageExtension: {
    display: "flex",
    height: "calc(100vh - 120px)",
    padding: "20px",
    width: "100%",
    transition: "width 0.3s ease",
  },
  selectionPadOuter: {
    flex: "0 0 400px",
    minHeight: "100%",
  },
  selectionPad: {
    overflowY: "scroll",
    backgroundColor: "#24222B",
    margin: "10px",
    borderRadius: "10px",
    height: "100%",
  },
  templateWritingPadOuter: {
    flex: "1",
    minHeight: "100%",
  },
  templateWritingPad: {
    backgroundColor: "#24222B",
    margin: "10px",
    padding: "10px",
    borderRadius: "10px",
    height: "100%",
  },
  editorContainer: {
    overflowY: "scroll",
    color: "black",
    height: "100%", // Set the height to 100%
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  inputContainer: {},
}));

const CustomTemplate = () => {
  const { templateId } = useParams();

  const classes = useStyles();
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  const [inputData, setInputData] = useState([]);
  const [templateData, setTemplateData] = useState(null); // State to store document template data
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isNewDocumentModalOpen, setIsNewDocumentModalOpen] = useState(false);

  const abortController = useRef(new AbortController());

  const uploadSuccess = () => {
    //fetchDocuments();
    toast.success("Document added successfully");
  };

  const uploadFailure = () => {
    toast.error("An error occured whilst uploading your document.");
  };

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "BLOCK_ALIGNMENT_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
      "IMAGE_BUTTON",
      "BLOCKQUOTE_BUTTON",
      "HORIZONTAL_RULE_BUTTON",
      "EMBED_BUTTON",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
      { label: "Code", style: "CODE" },
    ],
    BLOCK_ALIGNMENT_BUTTONS: [
      { label: "Align Left", style: "ALIGN_LEFT" },
      { label: "Align Center", style: "ALIGN_CENTER" },
      { label: "Align Right", style: "ALIGN_RIGHT" },
      { label: "Align Justify", style: "ALIGN_JUSTIFY" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
      { label: "Blockquote", style: "blockquote" },
      { label: "Code Block", style: "code-block" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
    LINK_BUTTONS: [
      { label: "Link", type: "LINK" },
      { label: "Unlink", type: "UNLINK" },
    ],
    HISTORY_BUTTONS: [
      { label: "Undo", type: "UNDO" },
      { label: "Redo", type: "REDO" },
    ],
    IMAGE_BUTTON: {
      label: "Image",
      onClick: () => {
        // Handle image insertion logic here
      },
    },
  };

  const handleSaveFile = () => {
    const plainText = editorValue.toString("markdown"); // Adjust according to your editor's format
    const blob = new Blob([plainText], { type: "text/plain" });
    const file = new File([blob], "exported_text.txt", { type: "text/plain" });
    // handleUpload(file);
    setFileToUpload(file);
  };

  const handleExport = () => {
    // Convert the editor content to plain text
    const plainText = editorValue.toString("markdown"); // or 'html', based on your need

    // Create a Blob for the text content
    const blob = new Blob([plainText], { type: "text/plain" });

    // Create an anchor element and trigger a download
    const downloadLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "contractly_export.txt"; // Name of the file to be downloaded
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url); // Clean up
  };

  const SaveToProject = () => {
    handleSaveFile();
    setIsNewDocumentModalOpen(true);
  };

  const handleCancelSSE = () => {
    abortController.current.abort(); // Abort the SSE connection

    // Create a new AbortController instance
    abortController.current = new AbortController();
  };
  useEffect(() => {
    // Fetch document template by ID
    getDocumentTemplateById(templateId)
      .then((template) => {
        setTemplateData(template);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching document template:", error);
      });
  }, [templateId]);

  useEffect(() => {
    // Fetch inputs using getDocumentTemplateInputs
    getDocumentTemplateInputs(templateId)
      .then((inputs) => {
        // Map the fetched inputs to the format expected in your component
        const formattedInputs = inputs.map((input) => ({
          id: input._id, // Or use a unique identifier
          key: input.inputKey,
          label: input.inputValue,
          value: "",
        }));
        setInputData(formattedInputs);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching inputs:", error);
      });
  }, [templateId]);

  const handleInputChange = (e, id) => {
    const updatedInputData = inputData.map((input) =>
      input.id === id ? { ...input, value: e.target.value } : input
    );
    setInputData(updatedInputData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const dynamicValues = {};
    inputData.forEach((input) => {
      dynamicValues[input.key] = input.value;
    });

    const inputMap = {};
    inputData.forEach((input) => {
      inputMap[input.key] = input.label;
    });

    setEditorValue(RichTextEditor.createEmptyValue());

    generateDocumentStream(
      templateId,
      dynamicValues,
      inputMap,
      setEditorValue,
      abortController.current
    ).then((response) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {}, [editorValue]);

  return (
    <div className={classes.basePageExtension}>
      <SaveGeneratedDocumentModal
        isOpen={isNewDocumentModalOpen}
        onClose={() => setIsNewDocumentModalOpen(false)}
        file={fileToUpload}
        onSuccess={() => uploadSuccess()}
        onFailure={() => uploadFailure()}
      />
      <div className={classes.selectionPadOuter}>
        <div className={classes.selectionPad}>
          <div style={{ padding: "20px" }}>
            <div style={{ paddingBottom: "15px" }}>
              <Link to="/templates" style={{ textDecoration: "none" }}>
                ← back
              </Link>
            </div>
            <h5 style={{ fontWeight: "700" }}>
              {" "}
              {templateData ? templateData.documentTemplateName : "Loading..."}
            </h5>
            <Form onSubmit={handleSubmit} className={classes.inputContainer}>
              {({ formProps }) => (
                <form {...formProps} id="generateDocument">
                  {inputData.map((input) => (
                    <div style={{ padding: "10px" }} key={input.id}>
                      <Label>{input.label}</Label>
                      <TextField
                        name={input.key}
                        value={input.value || ""}
                        onChange={(e) => handleInputChange(e, input.id)}
                      />
                    </div>
                  ))}
                  <div style={{ padding: "10px" }} key={"description"}>
                    <Label>Description</Label>
                    <TextField
                      name="description"
                      placeholder="Describe the contact you would like"
                      onChange={(e) => handleInputChange(e, "description")}
                    />
                  </div>
                </form>
              )}
            </Form>
          </div>

          <div
            style={{
              position: "sticky",
              backgroundColor: "#24222B",
              padding: "5px",
              bottom: "0px",
            }}
          >
            <hr />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Button
                  appearance="primary"
                  type="submit"
                  form="generateDocument"
                  onClick={handleSubmit}
                  isDisabled={isLoading}
                >
                  {isLoading ? (
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "5px",
                      }}
                    >
                      <ChaoticOrbit size={25} speed={1.5} color="#85B8FF" />
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faWandMagicSparkles} /> Generate
                      Document
                    </div>
                  )}
                </Button>

                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={handleCancelSSE}
                >
                  <FontAwesomeIcon icon={faCircleXmark} />
                </Button>
              </div>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Button onClick={handleExport}>Export</Button>
                <Button
                  style={{ marginLeft: "3px" }}
                  onClick={() => SaveToProject()}
                >
                  Save to Contractly
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.templateWritingPadOuter}>
        <div className={classes.templateWritingPad}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ top: "200px", position: "absolute" }}>
              <BookLoadingAnimation isLoading={isLoading} />
            </div>
          </div>
          <RichTextEditor
            className={classes.editorContainer}
            value={editorValue}
            onChange={(value) => setEditorValue(value)}
            toolbarConfig={toolbarConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomTemplate;
