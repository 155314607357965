import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import {
  getAllColleagues,
  updateColleagueSeat,
} from "../../action/colleagueAction";
import {
  getOrganisationCode,
  getJoinRequests,
  approveJoinRequest,
} from "../../action/organisationAction";
import Button from "@atlaskit/button/standard-button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));

const Colleagues = () => {
  const { theme } = useTheme();
  const classes = useStyles();
  const [documents, setDocuments] = useState([]);
  const [joinRequests, setJoinRequests] = useState([]);

  const [organisationCode, setOrganisationCode] = useState("");
  const [error, setError] = useState(null); // State to track errors

  const fetchColleagueData = async () => {
    try {
      const allColleagues = await getAllColleagues();
      setDocuments(allColleagues);
    } catch (error) {
      // Use a different variable name for the error caught in the catch block
      console.log("Failed to fetch colleagues. Please try again later.");
      setError("Failed to fetch colleagues. Please try again later.");
    }
  };

  const fetchJoinRequests = async () => {
    try {
      const response = await getJoinRequests();
      if (response.ok) {
        let data = await response.json();
        console.log("joinRequests: ", data);
        setJoinRequests(data);
      }
    } catch (error) {
      console.log("Failed to fetch join requests.");
    }
  };

  const colleagueTableHead = {
    cells: [
      { key: "name", content: "Name" },
      { key: "emailAddress", content: "Email Address" },
      { key: "seatStatus", content: "Seat Status" },
      { key: "actions", content: "Actions" },
    ],
  };

  const joinRequestTableHead = {
    cells: [
      { key: "username", content: "Name" },
      { key: "useremail", content: "Email Address" },
      { key: "status", content: "Request Status" },
      { key: "actions", content: "Actions" },
    ],
  };

  const FetchOrganisationCode = async () => {
    try {
      const response = await getOrganisationCode();
      if (response.error) {
        setOrganisationCode(
          "Error fetching organisation code please contact support."
        );
      } else {
        console.log(response);
        setOrganisationCode(response.organisationCode);
      }
    } catch (error) {}
  };

  const ApproveJoinRequest = async (joinOrganisationRequestId) => {
    const data = { joinOrganisationRequestId: joinOrganisationRequestId };
    try {
      const response = await approveJoinRequest(data);
      console.log(response);
      if (response.ok) {
        toast.success(`Colleague added!`);
        fetchColleagueData();
        fetchJoinRequests();
      } else {
        // Show the error message using react-toastify
        let data = await response.json();
        toast.error(data.error);
      }
    } catch (error) {
      // Show a generic error message with react-toastify
      toast.error(error);
      console.log(error);
    }
  };

  const UpdateColleagueSeat = async (colleagueId, seatStatus) => {
    const data = { colleagueId: colleagueId, seatStatus: seatStatus };
    try {
      const response = await updateColleagueSeat(data);
      if (response.error) {
        // Show the error message using react-toastify
        toast.error(response.error);
      } else {
        // Show a success message with react-toastify
        toast.success(`Colleague seat status updated to ${seatStatus}`);
        fetchColleagueData();
      }
    } catch (error) {
      // Show a generic error message with react-toastify
      toast.error(error.toString());
      console.log(error);
    }
  };

  useEffect(() => {
    // Initial data fetch when the component mounts
    fetchColleagueData();
    FetchOrganisationCode();
    fetchJoinRequests();
  }, []);

  const renderRows = () => {
    if (!Array.isArray(documents)) {
      return [];
    }

    return documents.map((document) => ({
      key: document._id,
      cells: [
        { key: "name", content: document.name },
        {
          key: "emailAddress",
          content: document.email,
        },
        {
          key: "seatStatus",
          content: document.seatStatus,
        },
        {
          key: "actions",
          content: (
            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <Button
                  onClick={() => UpdateColleagueSeat(document._id, "Active")}
                >
                  Activate
                </Button>
              </div>
              <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <Button
                  onClick={() => UpdateColleagueSeat(document._id, "Deactive")}
                >
                  Deactivate
                </Button>
              </div>
            </div>
          ),
        },
      ],
    }));
  };

  const renderJoinRequestsRows = () => {
    if (!Array.isArray(joinRequests)) {
      return [];
    }
    const filteredJoinRequests = joinRequests.filter(
      (joinRequest) => joinRequest.status !== "approved"
    );

    return filteredJoinRequests.map((joinRequest) => ({
      key: joinRequest._id,
      cells: [
        { key: "name", content: joinRequest.name },
        {
          key: "useremail",
          content: joinRequest.useremail,
        },
        {
          key: "status",
          content: joinRequest.status,
        },
        {
          key: "actions",
          content: (
            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <Button onClick={() => ApproveJoinRequest(joinRequest._id)}>
                  Approve
                </Button>
              </div>
            </div>
          ),
        },
      ],
    }));
  };

  return (
    <div className="base-page">
      <ToastContainer />
      <h1>Colleagues</h1>

      <p>
        View information about your colleagues. Your organisation code is:{" "}
        {organisationCode}
      </p>

      {error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <DynamicTable
          head={colleagueTableHead}
          rows={renderRows()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
        />
      )}

      <br />
      <br />

      <br />

      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h3>Pending Join Requests</h3>
        <br />

        <DynamicTable
          head={joinRequestTableHead}
          rows={renderJoinRequestsRows()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
        />
      </div>
    </div>
  );
};

export default Colleagues;
