import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const getOrganisationCode = async () => {
  try {
    const response = await fetch(`${API}/organisation/orgainsation-code`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error ||
          "Failed to fetch organisation Code. Please contact support."
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is an array of colleagues
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};

export const getOrganisationOwnerEmail = async () => {
  try {
    const response = await fetch(`${API}/organisation/owner-email`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const createOrganisation = async (data) => {
  try {
    const response = await fetch(`${API}/create-organisation`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const createJoinOrganisationRequest = async (data) => {
  try {
    const response = await fetch(`${API}/join-organisation`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const approveJoinRequest = async (data) => {
  try {
    const response = await fetch(`${API}/organisation/join-request/approve`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const getJoinRequests = async () => {
  try {
    const response = await fetch(`${API}/organisation/join-requests`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
