import React, { useState, useEffect } from "react";
import SectionMessage from "@atlaskit/section-message";
import { Field, Label } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button/standard-button";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../theme/ThemeContext";
import { useLocation } from "react-router-dom";
import Select from "@atlaskit/select";
import { API } from "../../config/config";
import {
  submitChat,
  fetchChat,
  fetchChatMessages,
  fetchProjects,
  fetchDocuments,
} from "../../action/chatAction";
import { fetchProjectDocumentById } from "../../action/projectDocumentAction";
import { fetchProjectById } from "../../action/projectAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  innerBasePage: {
    padding: "20px 20px 0px 40px",
    display: "flex",
    height: "calc(100vh - 100px)",
    flexDirection: "column",
  },
  header: {
    justifyContent: "center",
    width: "100%",
    display: "col",
    paddingBottom: "30px",
  },
  headerRow: {
    display: "flex",
    justifyContent: "center",
  },
  chatArea: {
    justifyContent: "center",
    width: "100%",
    marginBottom: "60px",
    flexGrow: 1,
  },
  userMessage: {
    width: "100%",
    padding: "10px",
    paddingLeft: "200px",
  },
  assistantMessage: {
    width: "100%",
    padding: "10px",
    paddingRight: "200px",
  },
  systemMessage: {
    width: "100%",
    padding: "10px",
  },
  avatar: {
    width: "150px",
  },
  chatFooterLight: {
    width: "100%",
    backgroundColor: "white",
    padding: "20px",
    position: "sticky",
    bottom: "0",
    borderTop: "2px solid #DEDCD7",
  },
  chatFooterDark: {
    width: "100%",
    backgroundColor: "#101118",
    padding: "20px",
    position: "sticky",
    bottom: "0",
    borderTop: "2px solid #24222B",
  },
  queryOuter: {
    display: "flex",
  },
  sendBtnOuter: {
    padding: "5px",
    marginTop: "2px",
  },
  queryTxtBoxOuter: {
    width: "100%",
  },
}));

const Index = () => {
  const history = useHistory();
  const { theme } = useTheme();
  const classes = useStyles();
  const [chatMessages, setChatMessages] = useState([]);
  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [documents, setDocuments] = useState([
    { label: "No Project Selected", value: "" },
  ]);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [selectedProjectDocument, setSelectedProjectDocument] =
    useState(undefined);
  let chatId = location.pathname.split("/")[2];

  const [formData, setFormData] = useState({
    chatMessageChainId: chatId,
    projectId: "",
    projectDocumentId: "",
    query: "",
  });

  if (chatId.toLowerCase() === "new") {
    chatId = "";
  }

  const handleFormChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const resetFormData = () => {
      setFormData({
        chatMessageChainId: "",
        projectId: "",
        projectDocumentId: "",
        query: "",
      });
    };

    const resetMessages = () => {
      setChatMessages([]);
    };

    const resetPage = () => {
      resetFormData();
      resetMessages();
      setSelectedProject(undefined);
      setSelectedProjectDocument(undefined);
    };

    // Fetch chat messages when the component mounts or chatId changes
    const fetchChatMessagesData = async () => {
      if (chatId === undefined || chatId === "") {
        resetPage();
        return;
      }
      try {
        const response = await fetchChatMessages(chatId); // Call the fetchChatMessages function
        if (response.ok) {
          const data = await response.json();
          setChatMessages(data.reverse()); // Set chat messages in state
        } else {
          // Handle error here
          console.error("Failed to fetch chat messages");
        }
      } catch (error) {
        console.error("An error occurred while fetching chat messages:", error);
      }
    };

    const fetchProjectsData = async () => {
      try {
        const response = await fetchProjects(); // Call the fetchProjects function
        if (response.ok) {
          const data = await response.json();
          let projectOptions;
          if (data.length === 0) {
            // If there are no projects, set "No Projects Created"
            projectOptions = [{ label: "No Projects Created", value: "" }];
          } else {
            // Map the projects to the desired format
            projectOptions = [
              { label: "Enter Project", value: "" },
              ...data.map((project) => ({
                label: project.projectName,
                value: project._id,
              })),
            ];
          }
          setProjects(projectOptions); // Set the mapped projects in state
        } else {
          // Handle error here
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("An error occurred while fetching projects:", error);
      }
    };

    const fetchChatData = async (id) => {
      if (id !== undefined && id !== "") {
        console.log("chatId: ", id);
        const response = await fetchChat(id);
        if (response.ok) {
          const data = await response.json();
          console.log("fetchChatData: ", data);
          if (data.projectId !== "" && data.projectId !== undefined) {
            setFormData({ ...formData, projectId: data.projectId });
            fetchProjectByIdData(data.projectId);
          }
          if (
            data.projectDocumentId !== "" &&
            data.projectDocumentId !== undefined
          ) {
            fetchProjectDocumentDataById(data.projectDocumentId);
          }
        } else {
          // Handle error here
          console.error("Failed to fetchChatData");
        }
      }
    };

    const fetchProjectByIdData = async (id) => {
      console.log("fetchProjectByIdData: ", id);
      const response = await fetchProjectById(id);
      if (response.ok) {
        const data = await response.json();
        console.log("fetchProjectById: ", data._id);
        //setSelectedProject(data.projectName);
        setSelectedProject(data);
      } else {
        // Handle error here
        console.error("Failed to fetchProjectById");
      }
    };

    const fetchProjectDocumentDataById = async (id) => {
      console.log("fetchProjectDocumentById: ", id);
      const response = await fetchProjectDocumentById(id);
      if (response.ok) {
        const data = await response.json();
        console.log("fetchProjectDocumentById: ", data);
        setSelectedProjectDocument(data);
        setFormData({
          ...formData,
          projectDocumentId: data._id,
        });
      } else {
        // Handle error here
        console.error("Failed to fetchProjectDocumentById");
      }
    };

    fetchChatData(chatId);
    fetchChatMessagesData();
    fetchProjectsData();
  }, [chatId]);

  const fetchDocumentData = async (id) => {
    if (id === "") {
      let documentOptions = [{ label: "No Project Selected", value: "" }];
      setDocuments(documentOptions);
    }
    try {
      const response = await fetchDocuments(id);

      if (response.ok) {
        const data = await response.json();

        if (data.length === 0) {
          let documentOptions = [{ label: "No Documents Uploaded", value: "" }];
          setDocuments(documentOptions);
        } else {
          // Map the projects to the desired format
          const documentOptions = [
            { label: "Select Document", value: "" }, // Add a "None" option
            ...data.map((document) => ({
              label: document.documentName,
              value: document._id, // Assuming "_id" is the project's unique identifier
            })),
          ];
          setDocuments(documentOptions); // Set the mapped projects in state
        }
      } else {
        // Handle error here
        console.error("Failed to fetchDocumentData");
      }
    } catch (error) {
      console.error("An error occurred while fetching projects:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    if (formData.projectId === undefined || formData.projectId === "") {
      if (selectedProject === undefined || selectedProject._id === undefined) {
        toast.error("Please select a project before continuting");
        return;
      } else {
        formData.projectId = selectedProject._id;
      }
    }

    if (
      (formData.projectId === "" || formData.projectId === undefined) &&
      (formData.chatMessageChainId === "" ||
        formData.chatMessageChainId === undefined)
    ) {
      toast.error("Select a project");
      return;
    }

    // Create a new message object with the user role and the query content
    const userMessage = {
      role: "user",
      content: formData.query,
    };

    // Add the user message to chatMessages
    setChatMessages((prevMessages) => [...prevMessages, userMessage]);

    let tempFormData = formData;

    setFormData({
      query: "",
    });

    try {
      let response = await submitChat(tempFormData);
      if (response.ok) {
        const data = await response.json();
        if (formData.chatId === "" || formData.chatId === undefined) {
          setFormData({
            chatId: data.chatMessageChainId,
          });
          history.push(`/chat/${data.chatMessageChainId}`);
        }

        const assistantMessage = {
          role: "assistant",
          content: data.answer,
        };

        setChatMessages((prevMessages) => [...prevMessages, assistantMessage]);
      } else {
        toast.error("Error submitting chat");
      }
    } catch (error) {
      toast.error("Error submitting chat:", error);
    }
  };

  return (
    <div className="base-page">
      <ToastContainer />

      <div className={classes.innerBasePage}>
        <div className={classes.systemMessage}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "25px",
                borderRadius: "20px",
                border: "2px solid rgba(99, 99, 99, 1)",
              }}
            >
              {/* <h5 className="text-center pt-4 pb-4">Select Data for Query</h5> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "20%",
                }}
              >
                <span
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingRight: "10px",
                    fontWeight: "700",
                  }}
                  className={`text-color-${theme}`}
                >
                  Query Project
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "40%",
                }}
              >
                <span
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingRight: "10px",
                  }}
                >
                  <Label htmlFor="select-project">Project:</Label>
                </span>
                {selectedProject === undefined ? (
                  <Select
                    inputId="select-project"
                    classNamePrefix="react-select"
                    options={projects}
                    menuPosition="fixed"
                    isMulti={false}
                    isSearchable={true}
                    placeholder="Enter project"
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption
                        ? selectedOption.value
                        : "";
                      handleFormChange("projectId", selectedOption.value);
                      fetchDocumentData(selectedValue);
                    }}
                  />
                ) : (
                  <>
                    <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                      {selectedProject.projectName}
                    </p>
                    {/* <input
                      type="hidden"
                      name="projectId"
                      value={selectedProject._id.toString()}
                    /> */}
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "40%",
                }}
              >
                <span
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingRight: "10px",
                  }}
                >
                  <Label htmlFor="select-project-document">Document:</Label>
                </span>
                {selectedProjectDocument === undefined ? (
                  <Select
                    inputId="select-project-document"
                    classNamePrefix="react-select"
                    options={documents}
                    menuPosition="fixed"
                    onChange={(selectedOption) => {
                      handleFormChange(
                        "projectDocumentId",
                        selectedOption.value
                      );
                    }}
                    isSearchable={true}
                    placeholder="Search Document"
                  />
                ) : (
                  <>
                    <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                      {selectedProjectDocument.documentName}
                    </p>
                    {/* <input
                      type="hidden"
                      name="projectDocumentId"
                      value={selectedProjectDocument._id.toString()}
                    /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.chatArea}>
          {chatMessages.map((message, index) =>
            // Conditional rendering based on message role
            message.role === "assistant" || message.role === "system" ? (
              <div key={index} className={classes.assistantMessage}>
                <SectionMessage title="Contractly AI">
                  <p
                    className={`text-color-${theme}`}
                    style={{ width: "100%" }}
                  >
                    {message.content}
                  </p>
                </SectionMessage>
              </div>
            ) : message.role === "user" ? (
              <div key={index} className={classes.userMessage}>
                <SectionMessage title="User" appearance="discovery">
                  <p
                    className={`text-color-${theme}`}
                    style={{ width: "100%" }}
                  >
                    {message.content}
                  </p>
                </SectionMessage>
              </div>
            ) : null
          )}
        </div>
        <div
          className={
            theme === "dark" ? classes.chatFooterDark : classes.chatFooterLight
          }
        >
          <div className={classes.queryOuter}>
            <div className={classes.queryTxtBoxOuter}>
              <form id="chatForm" onSubmit={handleSubmit}>
                <Field name="query" isRequired>
                  {({ fieldProps }) => (
                    <Textfield
                      {...fieldProps}
                      onChange={(e) =>
                        handleFormChange("query", e.target.value)
                      }
                      value={formData.query}
                      autocomplete="off"
                    />
                  )}
                </Field>
                <input
                  type="hidden"
                  name="chatMessageChainId"
                  value={formData.chatMessageChainId}
                />
                <input
                  type="hidden"
                  name="projectId"
                  value={formData.projectId}
                />
                <input
                  type="hidden"
                  name="projectDocumentId"
                  value={formData.projectDocumentId}
                />
              </form>
            </div>
            <div className={classes.sendBtnOuter}>
              <Button
                style={{
                  height: "40px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
                appearance="primary"
                type="submit"
                form="chatForm"
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
