import React, { useState, useEffect } from "react";
import { requireRole } from "../../action/authAction";
import { getAllOrganisations } from "../../action/adminAction";
import DynamicTable from "@atlaskit/dynamic-table";

const ManageOrganisations = () => {
  const [documents, setDocuments] = useState([]);
  const tableHead = {
    cells: [
      { key: "organisationName", content: "Organisation Name" },
      { key: "owner", content: "Owner" },
      { key: "organisationCode", content: "Organisation Code" },
      { key: "maxSeats", content: "Max Seats" },
      { key: "currentTier", content: "Current Tier" },
      { key: "discountRatePercentage", content: "Discount Rate Percentage" },
      { key: "organisationEmailDomain", content: "Organisation Email Domain" },
      { key: "actions", content: "Actions" },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      const orgData = await getAllOrganisations();
      setDocuments(orgData);
    };

    fetchData();
  }, []);

  const renderRows = () => {
    return documents.map((document) => ({
      key: document._id,
      cells: [
        { key: "organisationName", content: document.organisationName },
        {
          key: "owner",
          content: document.owner,
        },
        {
          key: "organisationCode",
          content: document.organisationCode,
        },
        { key: "maxSeats", content: document.maxSeats },
        { key: "currentTier", content: document.currentTier },
        {
          key: "discountRatePercentage",
          content: document.discountRatePercentage,
        },
        {
          key: "organisationEmailDomain",
          content: document.organisationEmailDomain,
        },
        {
          key: "actions",
          content: "Actions",
        },
      ],
    }));
  };

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <h2 style={{ fontWeight: "bold" }}>Manage Organisations</h2>
      <hr />

      <br />
      <DynamicTable
        head={tableHead}
        rows={renderRows()}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={false}
      />
    </div>
  );
};

export default requireRole([99])(ManageOrganisations);
