import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../theme/ThemeContext";
import { Field } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceMehBlank,
  faGavel,
  faFileContract,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button from "@atlaskit/button/standard-button";
import NewTemplate from "../../component/modals/templates/newTemplate";
import { getDocumentTemplates } from "../../action/documentTemplateAction"; // Replace with the correct import path

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
  cardOuter: {
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
  },
  card: {
    color: "white",
    backgroundColor: "#403d4a",
    width: "300px",
    height: "300px",
    padding: "20px",
    borderRadius: "10px",
    transition: "transform 0.2s",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  iconOuter: {
    display: "flex",
  },
  icon: {
    padding: "10px",
    borderRadius: "10px",
    minWidth: "50px",
    minHeight: "50px",
  },
  iconStyle: {
    fontSize: "30px",
    color: "black",
  },
  cardTitle: {
    fontWeight: "700",
    paddingTop: "15px",
  },
}));

const All = () => {
  const { theme } = useTheme();
  const classes = useStyles();
  const [showNewTemplateModal, setshowNewTemplateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch document templates from the server using the getDocumentTemplates function
    getDocumentTemplates()
      .then((data) => {
        setDocumentTemplates(data);
        setLoading(false);
      })
      .catch((error) =>
        console.error("Error fetching document templates:", error)
      );
  }, []);

  const refetchCustomTemplates = () => {
    // Fetch custom templates again here
    getDocumentTemplates()
      .then((data) => {
        setDocumentTemplates(data);
      })
      .catch((error) =>
        console.error("Error fetching document templates:", error)
      );
  };

  const openNewTemplateModal = () => {
    setshowNewTemplateModal(true);
  };

  const closeNewTemplateModal = () => {
    setshowNewTemplateModal(false);
  };

  const handleTemplateSearch = (value) => {
    setSearchValue(value);
  };

  const cardData = [
    {
      category: "Agreements",
      categoryDescription: "Create agreement content",
      cards: [
        {
          id: "653a7cbe50532971cd31ed98",
          title: "Memorandum of Understanding (MOU)",
          description:
            "Guided prompt to craft a Memorandum of Understanding (MOU) between two parties, detailing terms, responsibilities, and other essential clauses.",
          iconColor: "#FFAB00",
          icon: faFileContract,
        },
        {
          id: "653a63019907e159ee17fbe2",
          title: "Non-Disclosure Agreement",
          description: "Create a NDA fast.",
          iconColor: "#FFAB00",
          icon: faFaceMehBlank,
        },
        {
          id: "653a7f8c50532971cd31edb8",
          title: "Power of Attorney",
          description: "Give authority to somebody to act on your behalf.",
          iconColor: "#FFAB00",
          icon: faGavel,
        },
        {
          title: "One Shot Agreement Creator",
          description: "Create an entire agreement in one go.",
          iconColor: "#FFAB00",
          icon: faPaperclip,
        },
        // Add more cards to this category as needed
      ],
    },
    {
      category: "Clauses",
      categoryDescription: "Draft clauses",
      cards: [
        {
          title: "Clause Generator",
          description: "Instantly create a custom clause for your agreement.",
          iconColor: "#30FEA0",
          icon: faFileContract,
        },
        {
          title: "Amend a clause",
          description: "Make changes to an existing clause.",
          iconColor: "#30FEA0",
          icon: faFaceMehBlank,
        },
        {
          title: "Explain a clause",
          description:
            "Enter a clause and get an easy to read explanation of it.",
          iconColor: "#30FEA0",
          icon: faGavel,
        },
        {
          title: "Create Boilerplate Clauses",
          description:
            "This set of boilerplate clauses are designed to provide a solid foundation for any general contract.",
          iconColor: "#30FEA0",
          icon: faPaperclip,
        },
        // Add more cards to this category as needed
      ],
    },
    {
      category: "Letters",
      categoryDescription: "Content for generating letters",
      cards: [
        {
          id: "653a82fd50532971cd31edce",
          title: "Termination Letter",
          description: "A letter to terminate an agreement.",
          iconColor: "#0C6DFD",
          icon: faFileContract,
        },
        {
          id: "653a84f550532971cd31edee",
          title: "Cease and Desist Letter",
          description:
            "Send a letter when somebody is infringing your intellectual property.",
          iconColor: "#0C6DFD",
          icon: faFaceMehBlank,
        },
        {
          title: "Breach Notice Letter",
          description:
            "Ask AI to draft a breach notice letter for you. This formal document is sent to a party when they have failed to fulfill their contractual obligations.",
          iconColor: "#0C6DFD",
          icon: faGavel,
        },
        {
          title: "Demand Letter",
          description:
            "Ask the AI to draft a demand letter to formally request payment or resolution of a dispute.",
          iconColor: "#0C6DFD",
          icon: faPaperclip,
        },
        // Add more cards to this category as needed
      ],
    },
    // Add more categories and cards as needed
  ];

  const filteredCards = cardData.map((categoryData) => {
    const filteredCategoryCards = categoryData.cards.filter((card) =>
      card.title.toLowerCase().includes(searchValue.toLowerCase())
    );

    return {
      category: categoryData.category,
      categoryDescription: categoryData.categoryDescription,
      cards: filteredCategoryCards,
    };
  });

  return (
    <div className="base-page">
      <br />

      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h4 style={{ fontWeight: "bold" }}>Legal Document Templates</h4>
        <p>
          Generate legal documents in seconds using pre-defined or custom
          templates and AI.
        </p>

        <div style={{ padding: "15px" }}>
          <Field name="templateName">
            {({ fieldProps }) => (
              <Textfield
                {...fieldProps}
                onChange={(e) => handleTemplateSearch(e.target.value)}
                placeholder={"🔍 Search templates"}
                style={{
                  fontSize: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              />
            )}
          </Field>
        </div>
        <br />

        <NewTemplate
          isOpen={showNewTemplateModal}
          onClose={closeNewTemplateModal}
          onSuccess={refetchCustomTemplates}
        />
        <br />
      </div>
      <br />

      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <br />
        {loading ? (
          <p>Loading document templates...</p>
        ) : (
          <div key={"Custom"}>
            <h5 style={{ fontWeight: "700", paddingLeft: "10px" }}>
              Custom Templates
            </h5>
            <p style={{ opacity: 0.7, padding: "10px" }}>
              Templates you have uploaded and created
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Button appearance="primary" onClick={openNewTemplateModal}>
                Upload Custom Template
              </Button>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 22%)",
                gridGap: "2rem",
                marginRight: "1rem",
              }}
            >
              {documentTemplates.map((template, index) => (
                <Link
                  key={index}
                  to={`/template/${template._id}`}
                  className={classes.cardOuter}
                >
                  <div className={classes.card}>
                    <div className={classes.iconOuter}>
                      <span
                        className={classes.icon}
                        style={{ backgroundColor: "#FFAB00" }}
                      >
                        {/* You can customize the icon for document templates */}
                        <FontAwesomeIcon
                          className={classes.iconStyle}
                          icon={faPaperclip}
                        />
                      </span>
                    </div>
                    <h4 className={classes.cardTitle}>
                      {template.documentTemplateName}
                    </h4>
                    <p>{template.description}</p>
                    {/* You can also display additional template information here */}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
        <br />

        {filteredCards.map((categoryData, categoryIndex) => (
          <div key={categoryIndex}>
            <h5 style={{ fontWeight: "700", paddingLeft: "10px" }}>
              {categoryData.category}
            </h5>
            <p style={{ opacity: 0.7, padding: "10px" }}>
              {categoryData.categoryDescription}
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 22%)",
                gridGap: "2rem",
                marginRight: "1rem",
              }}
            >
              {categoryData.cards.map((card, cardIndex) => (
                <Link
                  key={cardIndex}
                  to={`/template/${card.id}`}
                  className={classes.cardOuter}
                >
                  <div className={classes.card}>
                    <div className={classes.iconOuter}>
                      <span
                        className={classes.icon}
                        style={{ backgroundColor: card.iconColor }}
                      >
                        <FontAwesomeIcon
                          className={classes.iconStyle}
                          icon={card.icon}
                        />
                      </span>
                    </div>
                    <h4 className={classes.cardTitle}>{card.title}</h4>
                    <p>{card.description}</p>
                  </div>
                </Link>
              ))}
            </div>
            <br />
            <br />
          </div>
        ))}
      </div>

      <br />
      <hr />
    </div>
  );
};

export default All;
