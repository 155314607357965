import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import RichTextEditor from "react-rte";
import cookie from "js-cookie";

export const generateDocument = async (data) => {
  try {
    const response = await fetch(`${API}/generate-document/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error ||
          "Failed to update seat status. Please contact support."
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is an array of colleagues
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};

export const generateDocumentStream = async (
  templateId,
  dynamicValues,
  inputMap,
  setValue,
  abortController
) => {
  try {
    const postData = { templateId, dynamicValues, inputMap };
    const response = await fetch(`${API}/generate-document/create/stream`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify(postData),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw (
        errorResponse.error ||
        "Failed to initiate stream. Please contact support."
      );
    }

    const { id } = await response.json();

    const eventSource = new EventSource(
      `${API}/generate-document/create/stream/${id}`,
      { withCredentials: true }
    );

    let totalStreamedContent = "";

    eventSource.onmessage = (event) => {
      if (abortController.signal.aborted) {
        return; // If the request is already aborted, exit early
      }
      console.log(event.data);
      totalStreamedContent += event.data;
      setValue(
        RichTextEditor.createValueFromString(totalStreamedContent, "html")
      );
    };

    eventSource.onopen = () => {
      // You can trigger any loading state if needed
    };

    eventSource.onerror = (error) => {
      eventSource.close();

      //console.log(error);
      return;
      // throw error;
    };
  } catch (error) {
    throw error;
  }
};
