import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const createDocumentTemplate = async (formData) => {
  try {
    const response = await fetch(`${API}/document-template`, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error || "Failed to create document template."
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is an array of colleagues
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};

export const getDocumentTemplateInputs = async (templateId) => {
  try {
    const response = await fetch(
      `${API}/document-template/inputs/${templateId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      }
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error || "Failed to get document template inputs"
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is an array of colleagues
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};

export const getDocumentTemplateById = async (templateId) => {
  try {
    const response = await fetch(
      `${API}/document-template/document/${templateId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error || "Failed to get document template."
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is a single document template
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};

export const getDocumentTemplates = async () => {
  try {
    const response = await fetch(`${API}/document-templates`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        errorResponse.error || "Failed to get document template."
      );
    }

    const responseData = await response.json();
    return responseData; // Assuming data is a single document template
  } catch (error) {
    throw error; // Throw the error to be caught and handled on the client side
  }
};
