import React from "react";

import FileViewer from "react-file-viewer";

const DocumentViewer = ({ fileLocation, fileType }) => {
  console.log("fileLocation: ", fileLocation);
  console.log("fileType: ", fileType);

  return (
    <FileViewer
      fileType={fileType}
      filePath={fileLocation}
      //errorComponent={CustomErrorComponent}
      //onError={this.onError}
    />
  );
};

export default DocumentViewer;
