import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faArrowRight,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import Badge from "@atlaskit/badge";
import Button from "@atlaskit/button/standard-button";
import { makeStyles } from "@material-ui/core/styles";
import Form, { Field, Label } from "@atlaskit/form";
import Textfield from "@atlaskit/textfield";
import {
  createOrganisation,
  createJoinOrganisationRequest,
} from "../../action/organisationAction";
import { useHistory } from "react-router-dom";
import { isAuth, updateUserLocalStorage } from "../../action/authAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles(() => ({
  orgModal: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
    minWidth: "500px",
  },
}));
const CreateOrganisation = () => {
  const classes = useStyles();
  const history = useHistory();

  const [view, setView] = useState("select-organisation-method");
  const [previousView, setPreviousView] = useState(null);
  const [animationInProgress, setAnimationInProgress] = useState(false);

  const [createOrgFormValues, setCreateOrgFormValues] = useState({
    organisationName: "",
  });
  const handleCreateOrgFormChange = (name, value) => {
    setCreateOrgFormValues({ ...createOrgFormValues, [name]: value });
  };

  const [createJoinOrgRequestFormValues, setCreateJoinOrgRequestFormValues] =
    useState({
      organisationCode: "",
    });

  const handleCreateJoinOrgRequestChange = (name, value) => {
    setCreateJoinOrgRequestFormValues({
      ...createJoinOrgRequestFormValues,
      [name]: value,
    });
  };

  const handleSelectOption = (option) => {
    setPreviousView(view);
    setView(option);
    setAnimationInProgress(true); // Start the animation
  };

  useEffect(() => {
    let user = isAuth();
    if (user && user.organisationId !== undefined) {
      handleSelectOption("create-organisation-success");
    }

    if (animationInProgress) {
      const timer = setTimeout(() => {
        setAnimationInProgress(false); // End the animation after its duration
      }, 400); // Replace 500 with your animation duration in milliseconds
      return () => clearTimeout(timer);
    }
  }, [animationInProgress]);

  useEffect(() => {
    if (view === "create-organisation-success") {
      const timeout = setTimeout(() => {
        history.push("/confirm-account");
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [view]);

  const shouldDisplayView = (key) => {
    if (animationInProgress) {
      // During animation, show both the current and the previous view
      return key === previousView;
    }
    // After animation, only show the current view
    return key === view;
  };

  const handleCreateOrganisationFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        ...createOrgFormValues,
      };

      const response = await createOrganisation(requestData);
      if (response.ok) {
        handleSelectOption("create-organisation-success");
        let data = await response.json();
        updateUserLocalStorage({ organisationId: data._id });

        // show success page
      }
    } catch (error) {
      toast.error(error);
      console.error("Error creating project:", error);
    }
  };

  const handleCreateJoinOrganisationRequestFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        ...createJoinOrgRequestFormValues,
      };

      const response = await createJoinOrganisationRequest(requestData);

      if (response.ok) {
        handleSelectOption("create-organisation-success");
        updateUserLocalStorage({ pendingOrganisationApproval: true });

        // show success page
      } else {
        let data = await response.json();
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error creating project:", error);

      toast.error(error);
    }
  };

  const views = [
    {
      key: "select-organisation-method",
      content: (
        <div
          className={view === "select-organisation-method" ? "" : "swipe-left"}
        >
          <div
            style={{
              height: "500px",
              display: "flex",
              justifyContent: "center",
              placeItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: "20px",
                }}
              >
                <FontAwesomeIcon style={{ fontSize: "50px" }} icon={faUsers} />
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: "20px",
                }}
              >
                <Badge appearance="added">Welcome!</Badge>
              </div>
              <h1 style={{ justifyContent: "center", display: "flex" }}>
                Create or Join an organisation!
              </h1>

              <p
                style={{
                  justifyContent: "center",
                  display: "flex",
                  opacity: 0.6,
                  fontWeight: 600,
                }}
              >
                Start optimising legal tasks with Contractly.
              </p>
              <hr />
              <br />
              <div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Button
                    appearance="primary"
                    onClick={() => handleSelectOption("create-organisation")}
                  >
                    Create new organisation{" "}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
                <br />

                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Button
                    appearance="subtle"
                    onClick={() => handleSelectOption("join-organisation")}
                  >
                    Join Organisation <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "create-organisation",
      content: (
        <div
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            placeItems: "center",
          }}
        >
          <div className={classes.orgModal}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h4 style={{ fontWeight: 600 }}>Create an Organisation</h4>
            </div>
            <hr />
            <Form onSubmit={handleCreateOrganisationFormSubmit}>
              {({ formProps }) => (
                <form {...formProps} id="createOrgForm">
                  <Field
                    name="organisationName"
                    label="Organisation/Company Name"
                    isRequired
                  >
                    {({ fieldProps }) => (
                      <Textfield
                        {...fieldProps}
                        onChange={(e) =>
                          handleCreateOrgFormChange(
                            "organisationName",
                            e.target.value
                          )
                        }
                        value={createOrgFormValues.organisationName}
                      />
                    )}
                  </Field>

                  <br />
                  {/* <Label htmlFor="multi-select-example">Add Colleagues</Label> */}
                </form>
              )}
            </Form>
            <div style={{ float: "left" }}>
              <Button
                appearance="subtle"
                onClick={() => handleSelectOption("select-organisation-method")}
              >
                Back
              </Button>
            </div>
            <div style={{ float: "right" }}>
              <Button
                appearance="primary"
                type="submit"
                form="createOrgForm"
                onClick={handleCreateOrganisationFormSubmit}
                autoFocus
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "join-organisation",
      content: (
        <div
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            placeItems: "center",
          }}
        >
          <div className={classes.orgModal}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h4 style={{ fontWeight: 600 }}>Join organisation</h4>
            </div>
            <hr />
            <Form onSubmit={handleCreateJoinOrganisationRequestFormSubmit}>
              {({ formProps }) => (
                <form {...formProps} id="createJoinOrganisationRequestForm">
                  <Field name="organisationCode" label="Organisation code">
                    {({ fieldProps }) => (
                      <Textfield
                        {...fieldProps}
                        onChange={(e) =>
                          handleCreateJoinOrgRequestChange(
                            "organisationCode",
                            e.target.value
                          )
                        }
                        value={createJoinOrgRequestFormValues.organisationCode}
                      />
                    )}
                  </Field>
                  <br />
                </form>
              )}
            </Form>
            <div style={{ float: "left" }}>
              <Button
                appearance="subtle"
                onClick={() => handleSelectOption("select-organisation-method")}
              >
                Back
              </Button>
            </div>
            <div style={{ float: "right" }}>
              <Button
                appearance="primary"
                type="submit"
                form="createJoinOrganisationRequestForm"
                onClick={handleCreateJoinOrganisationRequestFormSubmit}
                autoFocus
              >
                Request to join
              </Button>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "create-organisation-success",
      content: (
        <div
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            placeItems: "center",
          }}
        >
          <div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                padding: "20px",
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "50px", color: "#7DE2B8" }}
                icon={faCircleCheck}
              />
            </div>
            <h1 style={{ justifyContent: "center", display: "flex" }}>
              Success!
            </h1>

            <p style={{ justifyContent: "center", display: "flex" }}>
              Redirecting ...
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "organisation-already-assigned",
      content: (
        <div
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            placeItems: "center",
          }}
        >
          <div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                padding: "20px",
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "50px", color: "#7DE2B8" }}
                icon={faCircleCheck}
              />
            </div>
            <h1 style={{ justifyContent: "center", display: "flex" }}>
              Success!
            </h1>

            <p style={{ justifyContent: "center", display: "flex" }}>
              Organisation already assigned.
            </p>
            <br />
          </div>
        </div>
      ),
    },
    // Add more views here as needed
  ];

  return (
    <div className="base-page">
      <ToastContainer />
      {views.map((v) => (
        <div
          key={v.key}
          className={v.key === previousView ? "swipe-left" : ""}
          style={{ display: shouldDisplayView(v.key) ? "block" : "none" }}
        >
          {v.content}
        </div>
      ))}
    </div>
  );
};

export default CreateOrganisation;
