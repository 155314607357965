import React from "react";
import Typewriter from "typewriter-effect";

const Homepage = () => {
  return (
    <React.Fragment>
      <div
        className="justify-content-center d-flex"
        style={{ padding: "30px", height: "70vh" }}
      >
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  '<b style="font-size: 3rem; font-weight:600;">Experience Seamless Agreement Enhancement with AI Magic!</b>'
                )
                .callFunction(() => {
                  console.log("String typed out!");
                })
                .pauseFor(1000)
                .start();
            }}
            options={{
              html: true, // Allow HTML tags in typewriter
              delay: 40,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Homepage;
