import React from "react";
import SignInModal from "../component/modals/signInModal";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const returnUrl = queryParams.get("returnUrl");

  return (
    <React.Fragment>
      <ToastContainer />

      <div
        style={{ display: "flex", justifyContent: "center", margin: "30px" }}
      >
        <div
          style={{
            width: "500px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            border: "2px solid rgba(99, 99, 99, 0.2)",
            padding: "50px",
            borderRadius: "20px",
          }}
        >
          <h2 className="text-center pb-4" style={{ fontWeight: "700" }}>
            Sign In
          </h2>
          <SignInModal returnUrl={returnUrl} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signin;
