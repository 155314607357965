import React, { useState, useEffect } from "react";
import SectionMessage from "@atlaskit/section-message";
import DynamicTable from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import { useHistory } from "react-router-dom"; // Import useHistory
import { PieChart, Pie, Cell } from "recharts";
import { isAuth } from "../../action/authAction";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@atlaskit/badge";
import { API } from "../../config/config";
import cookie from "js-cookie";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));

const Index = () => {
  const [projects, setProjects] = useState([]);
  const history = useHistory(); // Initialize useHistory
  const { theme } = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailConfirmationSuccess = queryParams.get("emailConfirmationSuccess");

  if (emailConfirmationSuccess && emailConfirmationSuccess === "true") {
    toast.success("Email has been confirmed!");
  }
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    // let token = "";
    // if (process.browser) {
    //   token = cookie.get("jwt_auth");
    // }

    try {
      const response = await fetch(`${API}/projects`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching projects");
      }

      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const handleViewProject = (projectId) => {
    // Navigate to the specific project page using push
    history.push(`/project/${projectId}`);
  };

  const renderRows = () => {
    return projects.map((project) => ({
      key: project._id, // Assuming _id is the unique identifier of the project
      cells: [
        { key: "projectName", content: project.projectName },
        {
          key: "status",
          content: (
            <Badge
              appearance={
                project.projectStatus === "Open" ? "primary" : "warning"
              }
            >
              <span style={{ fontSize: "14px", padding: "4px" }}>
                {project.projectStatus}
              </span>
            </Badge>
          ),
        }, // Add status field to project model
        {
          key: "startDate",
          content: new Date(project.createdAt).toLocaleDateString(), // Convert datetime to date
        }, // Add startDate field to project model
        {
          key: "actions",
          content: (
            <Button onClick={() => handleViewProject(project._id)}>View</Button>
          ),
        },
      ],
    }));
  };

  const tableHead = {
    cells: [
      { key: "projectName", content: "Project Name" },
      { key: "status", content: "Status" },
      { key: "startDate", content: "Start Date" },
      { key: "actions", content: "Actions" },
    ],
  };

  return (
    <div className="base-page">
      <ToastContainer />

      <h1>Hello {isAuth().name.split(" ")[0]}!</h1>
      <br />

      <div
        className={
          theme === "dark"
            ? classes.homepageTileDark
            : classes.homepageTileLight
        }
      >
        <h5>Open Projects</h5>
        <br />
        <DynamicTable
          head={tableHead}
          rows={renderRows()}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={false}
        />
      </div>
      <br />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
        }}
      >
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Token Usage</h5>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                cx={150}
                cy={150}
                innerRadius={60}
                outerRadius={80}
                fill="#000000"
                paddingAngle={0}
                dataKey="value"
                stroke={0}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Search Queries</h5>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                cx={150}
                cy={150}
                innerRadius={60}
                outerRadius={80}
                fill="#000000"
                paddingAngle={0}
                dataKey="value"
                stroke={0}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
        <div
          className={
            theme === "dark"
              ? classes.homepageTileDark
              : classes.homepageTileLight
          }
        >
          <h5>Shared Projects</h5>
          <br />
          <DynamicTable
            head={tableHead}
            rows={renderRows()}
            defaultPage={1}
            loadingSpinnerSize="large"
            isLoading={false}
          />
        </div>
      </div>

      <br />

      <SectionMessage
        title="Need to create a new project?"
        appearance="discovery"
      >
        <p className={`text-color-${theme}`}>
          By creating a new project you unlock the ability to upload files and
          receive recommendations based on contracts and amendments. Seamlessly
          manage your tasks, collaborate with stakeholders, and assist in
          contract accuracy.
        </p>
      </SectionMessage>
    </div>
  );
};

export default Index;
