import React, { useState, useEffect } from "react";
import DynamicTable from "@atlaskit/dynamic-table";
import { useTheme } from "../../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import ViewDocumentModal from "../../component/modals/projects/viewDocumentModal";
import { API } from "../../config/config";
import Badge from "@atlaskit/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel } from "@fortawesome/free-solid-svg-icons";
import cookie from "js-cookie";

const useStyles = makeStyles(() => ({
  homepageTileDark: {
    borderRadius: "0.5rem",
    backgroundColor: "#24222b",
    padding: "20px",
  },
  homepageTileLight: {
    borderRadius: "0.5rem",
    backgroundColor: "#ffffff",
    padding: "20px",
  },
}));
const Legislation = () => {
  const [documents, setDocuments] = useState([]);
  const { theme } = useTheme();
  const classes = useStyles();

  const [isViewDocumentModalOpen, setIsViewDocumentModalOpen] = useState(false); // Add state for the modal
  const [selectedDocument, setSelectedDocument] = useState({}); // Add state to store the selected document

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${API}/project-document/all/documents`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error fetching documents");
      }

      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const openDocumentViewer = (document) => {
    setSelectedDocument(document); // Store the selected document
    setIsViewDocumentModalOpen(true); // Open the modal
  };

  const data = [
    {
      key: "row-1",
      cells: [
        { key: "name", content: "Sample Legislation #1" },
        { key: "type", content: "PDF" },
        { key: "uploadedDate", content: "12/10/2023" },
      ],
    },
    // Add more rows as needed
  ];

  const tableHead = {
    cells: [
      { key: "documentName", content: "Document Name" },
      { key: "type", content: "Type" },
      { key: "uploadedDate", content: "Uploaded Date" },
      { key: "actions", content: "Actions" },
    ],
  };

  return (
    // <div className="base-page">
    //   <ViewDocumentModal
    //     isOpen={isViewDocumentModalOpen}
    //     onClose={() => setIsViewDocumentModalOpen(false)}
    //     signedUrl={selectedDocument.signedUrl} // Pass the signedUrl of the selected document
    //     fileType={selectedDocument.fileType} // Pass the fileType of the selected document
    //   />
    //   <div
    //     className={
    //       theme === "dark"
    //         ? classes.homepageTileDark
    //         : classes.homepageTileLight
    //     }
    //   >
    //     <h5>Uploaded Legislation</h5>
    //     <br />
    //     <DynamicTable
    //       head={tableHead}
    //       rows={data}
    //       defaultPage={1}
    //       loadingSpinnerSize="large"
    //       isLoading={false}
    //     />
    //   </div>
    // </div>

    <div className="base-page">
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          placeItems: "center",
        }}
      >
        <div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <FontAwesomeIcon style={{ fontSize: "50px" }} icon={faGavel} />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "20px",
            }}
          >
            <Badge appearance="added">Coming soon!</Badge>
          </div>
          <h1 style={{ justifyContent: "center", display: "flex" }}>
            Legislation
          </h1>

          <p style={{ justifyContent: "center", display: "flex" }}>
            Use contractly against local legislation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Legislation;
