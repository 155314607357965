import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const getAllOrganisations = async () => {
  try {
    const response = await fetch(`${API}/admin/organisations`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
    });
    const data = await response.json();
    return data; // Assuming data is an array of organizations
  } catch (error) {
    console.error(error);
    return [];
  }
};
