import { useEffect } from "react";
import { API } from "../../config/config";
import cookie from "js-cookie";

import { isAuth } from "../../action/authAction";

const UseHubSpotChat = () => {
  const user = isAuth();

  useEffect(() => {
    if (user) {
      fetch(`${API}/hubspot/generate-token`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("hubspot data: ", data);
          window.hsConversationsSettings = {
            loadImmediately: true,
            identificationEmail: data.email,
            identificationToken: data.token,
          };

          const hsScript = document.createElement("script");
          hsScript.src = "//js-na1.hs-scripts.com/44088340.js";
          hsScript.async = true;
          hsScript.defer = true;
          hsScript.id = "hs-script-loader";
          hsScript.onload = () => {
            if (window.HubSpotConversations) {
              window.HubSpotConversations.widget.load();
            }
          };
          document.body.appendChild(hsScript);
        })
        .catch((error) => {
          console.error("Error fetching HubSpot token:", error);
        });
    }
  }, []);
};

export default UseHubSpotChat;
