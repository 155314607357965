import React, { useState, useEffect } from "react";
import { resetPassword, validatePasswordToken } from "../../action/authAction";
import { withRouter } from "react-router-dom";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button/standard-button";

const ResetPasswordModal = ({ token }) => {
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
    message: "",
    error: "",
    loading: false,
    is_valid: false,
  });

  const { newPassword, confirmPassword, message, loading, is_valid, error } =
    values;

  const handleLoad = async () => {
    setValues({ ...values, loading: true });
    try {
      const validToken = await validatePasswordToken(token);
      if (validToken) {
        const errorMessages = {
          expired_invalid_token:
            "The reset token has expired or is no longer invalid.",
          invalid_token: "The reset token is invalid.",
          authentication_error: "There was an authentication error.",
        };
        setValues({
          ...values,
          is_valid: validToken["ok"] === "true",
          error:
            validToken["ok"] === "true"
              ? ""
              : errorMessages[validToken["error"]] ||
                "An unknown error occurred.",
        });
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });

    if (newPassword === confirmPassword) {
      try {
        const data = await resetPassword(token, newPassword);
        if (data.message) {
          setValues({ ...values, message: data.message, loading: false });
        } else {
          setValues({ ...values, message: data.error, loading: false });
        }
      } catch (error) {
        setValues({
          ...values,
          message: "An error occurred. Please try again later.",
          loading: false,
        });
      }
    } else {
      setValues({
        ...values,
        message: "Passwords do not match.",
        loading: false,
      });
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value, message: "" });
  };

  const showLoading = () =>
    loading ? <div className="alert alert-info">Loading...</div> : "";
  const showError = () =>
    error ? (
      <div className="alert alert-danger">
        {error} <br />
        Please try submitting the reset form again{" "}
        <a href="/ForgotPassword">here</a>
      </div>
    ) : (
      ""
    );
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";

  const resetPasswordForm = () => {
    return (
      <>
        <div className="custom-modal">
          <form onSubmit={handleSubmit}>
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="New Password"
              onChange={handleChange("newPassword")}
              required
              type="password"
            />

            <br />
            <Textfield
              appearance="standard"
              label="Standard"
              placeholder="Confirm Password"
              onChange={handleChange("confirmPassword")}
              required
              type="password"
            />
            <br />

            <Button
              style={{ float: "right" }}
              appearance="primary"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </div>
      </>
    );
  };

  return (
    <div className="container">
      {showLoading()}
      {showError()}
      {showMessage()}
      {is_valid && resetPasswordForm()}
    </div>
  );
};

export default withRouter(ResetPasswordModal);
