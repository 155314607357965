import React, { useEffect, useState } from "react";
import { isAuth, signup, signin, authenticate } from "../../action/authAction";
import { withRouter } from "react-router-dom";
import Textfield from "@atlaskit/textfield";
import { useTheme } from "../../theme/ThemeContext";
import { API } from "../../config/config";
import cookie from "js-cookie";

const SignUpModal = ({ history }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    error: "",
    loading: false,
    message: "",
    showForm: true,
  });

  const {
    name,
    email,
    password,
    confirmPassword,
    error,
    loading,
    message,
    showForm,
  } = values;

  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    isAuth() && history.push("/");
  }, [history]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setValues({ ...values, error: "Passwords do not match", loading: false });
      return;
    }

    setValues({ ...values, loading: true, error: "" });

    try {
      const user = { name, email, password };
      const data = await signup(user);

      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        setValues({
          ...values,
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          error: "",
          loading: false,
          message: data.message,
          showForm: false,
        });
        let tempUser = { email: user.email, password: user.password };
        const signInData = await signin(tempUser);
        if (!signInData) {
          setValues({ ...values, error: "Sign in error", loading: false });
        } else if (signInData.error) {
          setValues({ ...values, error: signInData.error, loading: false });
        } else {
          authenticate(signInData, () => {
            setTheme();
            if (isAuth() && isAuth().role === 99) {
              history.push("/admin");
            } else {
              history.push("/dashboard");
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setTheme = async () => {
    try {
      // Fetch the user's current theme from the server
      const userTheme = await getTheme(); // Assuming getTheme is a function that fetches the user's theme from the server

      if (userTheme && userTheme !== theme) {
        toggleTheme(); // Update the theme in the React context
      }
    } catch (error) {
      console.error("Error setting theme:", error);
    }
  };

  const getTheme = async () => {
    try {
      const response = await fetch(`${API}/user/theme/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
          Authorization: `Bearer ${cookie.get("jwt_auth")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user theme");
      }
      if (response.ok) {
        const data = await response.json();
        const userTheme = data.theme;
        return userTheme;
      }
    } catch (error) {
      console.error("Error fetching user theme:", error);
    }
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: "", [name]: e.target.value });
  };

  const showLoading = () =>
    loading ? <div className="alert alert-info">Loading...</div> : null;

  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : null;

  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : null;

  const signupForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Textfield
          appearance="standard"
          label="Standard"
          placeholder="Type your name"
          onChange={handleChange("name")}
          required
          type="text"
          value={name}
        />
        <br />
        <Textfield
          appearance="standard"
          label="Standard"
          placeholder="Type your email"
          onChange={handleChange("email")}
          required
          type="email"
          value={email}
        />
        <br />

        <Textfield
          appearance="standard"
          label="Standard"
          placeholder="Type your password"
          onChange={handleChange("password")}
          required
          type="password"
          value={password}
        />
        <br />
        <Textfield
          appearance="standard"
          label="Standard"
          placeholder="Confirm Password"
          onChange={handleChange("confirmPassword")}
          required
          type="password"
          value={confirmPassword}
        />
      </div>
      <br />
      <button
        type="submit"
        className="btn btn-primary"
        style={{ float: "right" }}
      >
        Sign Up
      </button>
    </form>
  );

  return (
    <div className="container">
      {showLoading()}
      {showError()}
      {showMessage()}
      {showForm && signupForm()}
    </div>
  );
};

export default withRouter(SignUpModal);
