import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { isAuth } from "../../action/authAction";

const Protected = ({ history }) => {
  let route = useLocation().pathname;

  useEffect(() => {
    if (!isAuth()) {
      //allow access to routes without being signed in
      switch (true) {
        case route.includes("/ForgotPassword"):
          history.push("/ForgotPassword");
          break;
        case route.includes("/resetpassword"):
          history.push(route);
          break;
        default:
          const returnUrl = encodeURIComponent(route);
          history.push(`/signin?returnUrl=${returnUrl}`);
          break;
      }
    }
    //else if (isAuth().role !== 1)
  }, []);

  return <div></div>;
};

export default withRouter(Protected);
