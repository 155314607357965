import React, { useState } from "react";
import Button from "@atlaskit/button";
import { requireRole } from "./../../action/authAction"; // Import the requireAdmin function
import CreateOrganisationModal from "../../component/modals/admin/CreateOrganisationModal";

const Index = () => {
  const [isCreateOrganisationModalOpen, setCreateOrganisationModalOpen] =
    useState(false);

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <h2 style={{ fontWeight: "bold" }}>Admin panel</h2>
      <hr />
      <br />
      <h3>Actions</h3>
      <h4>
        <Button onClick={() => setCreateOrganisationModalOpen(true)}>
          Create Organisation
        </Button>
      </h4>

      <CreateOrganisationModal
        isOpen={isCreateOrganisationModalOpen}
        onClose={() => setCreateOrganisationModalOpen(false)}
      />
    </div>
  );
};

export default requireRole([99])(Index);
