import fetch from "isomorphic-fetch";
import { API } from "../config/config";
import cookie from "js-cookie";

export const signup = (user) => {
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      return err;
    });
};

export const signin = async (user) => {
  try {
    const response = await fetch(`${API}/signin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error); // Throw the error message from the server
    }

    return data;
  } catch (err) {
    console.error(err);
    return { error: err.message || "Sign in error" };
  }
};

export const signout = (next) => {
  removeCookie("jwt_auth");
  removeLocalStorage("user");
  next();

  return fetch(`${API}/signout`, {
    method: "GET",
  })
    .then((response) => {
      console.log("signout success");
    })
    .catch((err) => {
      console.log(err);
    });
};

// set the cookie
export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

// remove the cookie
export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

// get cookie
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

//localStorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// remove from localStrorage
export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key);
  }
};

// authenticate user by pass data to cookie and localStorage
export const authenticate = (data, next) => {
  setCookie("jwt_auth", data.jwt_auth);
  setLocalStorage("user", data.user);
  next();
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie("jwt_auth");
    if (cookieChecked) {
      if (
        localStorage.getItem("user") &&
        localStorage.getItem("user") !== "undefined"
      ) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return false;
      }
    }
  }
};

export const updateUserLocalStorage = (updatedUserData) => {
  if (process.browser) {
    const user = isAuth();
    if (user) {
      const updatedUser = { ...user, ...updatedUserData };
      setLocalStorage("user", updatedUser);
    }
  }
};

export const fetchAndUpdateUserLocalStorage = async () => {
  const response = await fetch(`${API}/signed-in-user-info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookie.get("jwt_auth")}`,
    },
    credentials: "include",
  });

  if (response.ok) {
    let data = await response.json();
    let userData = data.user;
    console.log(data);
    updateUserLocalStorage(userData);
  }
};

export const sendResetLink = (email) => {
  return fetch(`${API}/forgot-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).then((response) => response.json());
};

export const resetPassword = async (resetLink, newPassword) => {
  try {
    const response = await fetch(`${API}/reset-password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({
        resetLink,
        newPass: newPassword,
      }),
      credentials: "include",
    });

    return response.json();
  } catch (error) {
    return { error: "An error occurred." };
  }
};

export const validatePasswordToken = async (resetLink) => {
  try {
    const response = await fetch(`${API}/validate-password-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({
        resetLink,
      }),
      credentials: "include",
    });

    return response.json();
  } catch (error) {
    return { error: "An error occurred." };
  }
};

export const validateEmailConfirmationToken = async (token) => {
  try {
    const response = await fetch(`${API}/validate-email-confirmation-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      body: JSON.stringify({
        token,
      }),
      credentials: "include",
    });

    return response;
  } catch (error) {
    return { error: "An error occurred." };
  }
};

export const resendEmailConfirmation = async () => {
  try {
    const response = await fetch(`${API}/resend-email-confirmation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookie.get("jwt_auth")}`,
      },
      credentials: "include",
    });

    return response.json();
  } catch (error) {
    return { error: "An error occurred." };
  }
};

export const requireRole = (allowedRoles) => (WrappedComponent) => {
  const WithAdminCheck = (props) => {
    const user = isAuth();

    // Check if user is not null and has a role
    if (user && user.role) {
      const userRole = user.role;

      // Check if the user's role is in the allowedRoles
      if (allowedRoles.includes(userRole)) {
        return <WrappedComponent {...props} />;
      }
    }

    // If user is null or doesn't have the right role
    return <div>Access denied. You must be an admin to view this page.</div>;
  };

  return WithAdminCheck;
};
